import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';

import { ApolloClient, useApolloClient } from '@apollo/client';
import { getToken } from '../../apollo/getToken';
import { getProfile } from '../../containers/data/getProfile';

import { createClient } from '@supabase/supabase-js';

import * as config from '../../settings.json';

import { useDispatch, useSelector } from 'react-redux';
import { setPlatform } from '../../redux/action/utils';

import { INVITE_USER_PATH, PROFILE_SETUP_PATH, returnEnvironment } from '../../helpers/config';

import ScrapPaths from './ScrapPaths';
import CollectionPaths from './CollectionPaths';

import { utilsTypes } from '../../types/utils';

import AuthWrapper from '../../containers/AuthWrapper';
import LoginWrapper from '../../containers/Login';
import RegisterWrapper from '../../containers/Register';
import { ForgotPassword } from '../Account';
import AccountRecoveryWrapper from '../../containers/AccountRecovery';
import AcceptCollaboratorWrapper from '../../containers/AcceptCollaboratorWrapper';
import PublicFeedNavigation from '../Navigations/PublicFeedNavigation';
import ScrapDetailsPublic from '../Details/ScrapDetailsPublic';
import VerifyEmailWrapper from '../../containers/VerifyEmail';

import MyFeedContainer from '../../containers/MyFeedContainer';

import { Copyright, PrivacyPolicy, TermsAndConditions } from '../TermPages';
import NewHelpPage from '../NewHelpPage';
import GlobalSearchPublicWrapper from '../../containers/GlobalSearchPublicWrapper';
import GlobalSearchPublicProfileWrapper from '../../containers/GlobalSearchPublicProfileWrapper';
import Home from '../home';
import { ReduxStateType } from '../../redux/store';
import {
  setSupabaseClient,
  setSupabaseMessage,
} from '../../redux/action/supabaseActions';
import ShareScrapContainer from '../../containers/ShareScrapContainer';
import CollectionPageContainer from '../../containers/CollectionPageContainer';
import StatusPage from "../StatusPage";

interface Proptypes {
  client: ApolloClient<object>;
  token: string;
  isServer?: boolean;
}

// Routes for which authentication from frontend will be processed to restrict the user from going in these routes
// if he's already logged in. This is intentionally done for preventing abnormal behavior happening in safari browser where
// cookies don't get cleared when logging out
const routesToCheck = ['/login', '/register', INVITE_USER_PATH];

/*
 * COMPONENT DESCRIPTION
 * This component is used to render all routes in App.tsx
 *
 */

function AppRoutes({ client: propClient, token: propToken, isServer }: Proptypes) {
  const user = useSelector((state: ReduxStateType) => state.user);
  const { user_id, onboard_status } = user;
  const apolloClient = useApolloClient();
  const client = propClient ?? apolloClient;
  const parsedToken = getToken();
  const token = propToken ?? parsedToken;
  const { pathname } = useLocation();
  const history = useHistory();
  const [isCheckingAuth, setIsCheckingAuth] = useState(
    routesToCheck.includes(pathname)
  );
  const dispatch = useDispatch();

  // This function checks if user is authenticated or not when he is at either login or register page
  // and then redirects them to scrappi home page if authenticated
  async function authHandler() {
    try {
      if (!routesToCheck.includes(pathname)) return setIsCheckingAuth(false);
      setIsCheckingAuth(true);
      if (token) {
        const res = await client.query({ query: getProfile });
        const isAuthenticated = res?.data?.profile?.data?.userName;
        if (isAuthenticated) window.location.href = '/';
        else {
          setIsCheckingAuth(false);
        }
      } else setIsCheckingAuth(false);
    } catch (error) {
      setIsCheckingAuth(false);
    }
  }
  // #U1: Used for frontend authentication and setting platform in which dashboard is running
  useEffect(() => {
    authHandler();

    let platform: utilsTypes['platform'] = null;
    try {
      if (/iphone|ipod|ipad/i.test(window.navigator.userAgent.toLowerCase())) {
        platform = 'ios-mobile';
      }
    } catch (error) {
      console.warn('error', error);
    }
    dispatch(setPlatform(platform));
  }, []);
  // #U3: Used for setting supabase client if user data
  //  is fetched successfully and also set the subscription to table change for notifications
  useEffect(() => {
    if (user_id) {
      const supabaseClient = createClient(
        config.supabaseApiUrl,
        config.supabaseAnonKey
      );
      dispatch(setSupabaseClient(supabaseClient));
      const environment = returnEnvironment();
    
      let table = 'notifications'
      
      if(environment && environment !== 'production') table += '-' + environment
      const subscription = supabaseClient
        .channel('custom-all-channel')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table,
            filter: `user_id=eq.${user_id}`,
          },
          (payload) => {
            dispatch(setSupabaseMessage(payload));
          }
        )
        .subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user_id]);
  // #U4: Used for checking if user has completed the profile setup by entering his username
  // and display name. If not then redirect to profile setup page
  useEffect(() => {
    if(user.onboard_status && ![PROFILE_SETUP_PATH, '/search'].includes(pathname)) {
      history.push(PROFILE_SETUP_PATH)
    }
  }, [user])

  if (isCheckingAuth) return null;
  return (
    <Switch>      
      <Route path="/auth" exact render={(props) => <AuthWrapper />} />
      <Route
        path="/login"
        exact
        render={(props) => <LoginWrapper {...props} />}
      />
      <Route
        path={['/register', INVITE_USER_PATH]}
        exact
        render={(props) => <RegisterWrapper {...props} />}
      />
      <Route
        path="/reset-password"
        exact
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        path="/account-recovery"
        exact
        render={(props) => <AccountRecoveryWrapper {...props} />}
      />
      <Route
        path="/accept-collaborator"
        exact
        render={(props) => (
          <AcceptCollaboratorWrapper {...props} token={token} />
        )}
      />

      {/* if route in /onboard redirect to root */}
      <Route path="/onboard" exact render={() => <Redirect to="/" />} />

      <Route
        path={[
          '/scrap/share/:id',
          '/:username/scrap/:slug/:id',
          '/:username/scrap/:slug/:id/:private_key',
        ]}
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <div className="scrap-details">
              {props.match.params.id.includes('-') ? (
                <ScrapDetailsPublic {...props} client={client} />
              ) : (
                <ShareScrapContainer {...props} client={client} />
              )}
            </div>
          </PublicFeedNavigation>
        )}
      />
      <Route
        path={[
          '/c/share/:id',
          '/:username/c/:id/:cID',
          '/:username/c/:id/:cID/:private_key',
        ]}
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <>
              <CollectionPageContainer {...props} isPublic={!user.user_id} view="collectionPreview" />
            </>
          </PublicFeedNavigation>
        )}
      />
      <Route
        path="/verify-email"
        exact
        render={(props) => <VerifyEmailWrapper {...props} />}
      />
      <Route
        path="/privacy-policy"
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <PrivacyPolicy />
          </PublicFeedNavigation>
        )}
      />
      <Route
        path="/terms-and-conditions"
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <TermsAndConditions />
          </PublicFeedNavigation>
        )}
      />
      <Route
        path="/copyright-and-trademark"
        exact
        render={(props) => <Copyright />}
      />
      <Route
        path="/help"
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <NewHelpPage {...props} />
          </PublicFeedNavigation>
        )}
      />
      <Route
        path="/status"
        exact
        render={(props) => (
          <PublicFeedNavigation>
            <StatusPage />
          </PublicFeedNavigation>
        )}
      />
      <Route path="/discover" exact render={(props) => <MyFeedContainer />} />
      {!getToken() && (
        <Route
          path="/search"
          render={(props) => <GlobalSearchPublicWrapper {...props} />}
        />
      )}
      {/* THE CONDITION NEEDS TO REPEATED BECAUSE THE FRAGMENTED COMBINED ROUTES WILL MAKE BOTH THE ROUTES RUN ALONGSIDE RATHER THAN AS DIFFERENT PATHS */}
      {!getToken() && (
        <Route
          exact
          path="/:username"
          render={(props) => <GlobalSearchPublicProfileWrapper {...props} />}
        />
      )}
      <Route path="/" render={(props) => <Home />} />

      
    </Switch>
  );
}
export default AppRoutes;
export {
  ScrapPaths as ScrapRouter,
  CollectionPaths as CollectionRouter,
};