import React from 'react';
import { Link } from 'react-router-dom';
import Trust from '../../../../Trust';

interface PropTypes {
  screenName: string;
  profileImage?: string;
  name: string;
  description: string;
  link?: string;
  profileData?: {
    user_id: number;
    userName: string;
    avatar?: string;
    display_name: string;
    is_trusted: boolean;
  };
  hideUser?: boolean;
}

const XFollowsCard = (props: PropTypes) => {
  const { screenName, profileImage, name, description, profileData, link, hideUser } = props;
  const user_id = profileData?.user_id;
  const userName = profileData?.userName;
  const avatar = profileData?.avatar;
  const display_name = profileData?.display_name;
  const is_trusted = profileData?.is_trusted;

  return (
    <>
      {link && (
        <a
          className="x-follows__link"
          href={link ? link : `https://twitter.com/${screenName}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="x-follows__user">
            {profileImage && <img src={profileImage} alt={screenName} />}
            <p>
              {name && <h2>{name}</h2>}

              {screenName && (
                <h3>
                  {profileImage && '@'}
                  {screenName}
                </h3>
              )}

              {description && <span>{description}</span>}
            </p>
          </div>
        </a>
      )}

      {!link && (
        <div className="x-follows__user">
          {profileImage && <img src={profileImage} alt={screenName} />}
          <p>
            {name && <h2>{name}</h2>}

            {screenName && (
              <h3>
                {profileImage && '@'}
                {screenName}
              </h3>
            )}

            {description && <span>{description}</span>}
          </p>
        </div>
      )}

      {user_id && !hideUser && (
        <Link to={`/${userName}`}>
          <div className="x-follows__user small">
            {avatar && (
              <img
                src={avatar ? avatar : '/dashboard/img/default-avatar.jpg'}
                alt={userName}
              />
            )}
            <p>
              {display_name && (
                <h2>
                  {display_name}{' '}
                  <Trust isTrusted={is_trusted} userId={user_id} view="icon" />
                </h2>
              )}

              {userName && <h3>@{userName} </h3>}
            </p>
          </div>
        </Link>
      )}
    </>
  );
};

export default XFollowsCard;
