import React from 'react';

const MarketingPrice = () => {
  return (
    <div className="s-new-price">
      <div className="f-ani-vii_tab-panel-tile v2">
        <div className="f-ani-vii_panel-mian">
          <div className="f-ani-vii_panel-mian-price">
            <div className="s-price-text">Free</div>
            <div className="s-price-number">$0</div>
            <a
              href="https://app.scrappi.com/register"
              className="f-ani-vii_panel-button short v2 w-inline-block"
            >
              <div>Sign up</div>
              <div className="f-ani-vii_panel-buttonimg">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb6032_Arrow%20right.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
            </a>
          </div>
          <div className="f-ani-vii_panel-mian-line"></div>
          <ul role="list" className="f-ani-vii_panel-mian-list">
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Unlimited Web Page Scraps</div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">
                Unlimited Notes and Highlights
              </div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Unlimited Collections</div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Unlimited Share Groups</div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Unlimited Contacts</div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">
                Unlimited Single Image Scraps
              </div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Unlimited Screenshots</div>
            </li>
            <li className="f-ani-vii_panel-list-item">
              <div className="f-ani-vii_panel-list-img">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb601e_zir%20price.svg"
                  alt=""
                  className="object-contain"
                />
              </div>
              <div className="s-text-14px-14px">Up to 5GB of Scraps</div>
            </li>
          </ul>
        </div>
        <a
          href="https://app.scrappi.com/register"
          target="_blank"
          className="f-ani-vii_panel-button short v3 w-inline-block"
        >
          <div>Sign Up</div>
          <div className="f-ani-vii_panel-buttonimg">
            <img
              loading="lazy"
              src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb6032_Arrow%20right.svg"
              alt=""
              className="object-contain"
            />
          </div>
        </a>
        <div className="s-new-price-line"></div>
      </div>
    </div>
  );
};

export default MarketingPrice;