import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const StorageIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 2.33335C0.666748 1.41289 1.41501 0.666687 2.33813 0.666687H5.63376C5.94796 0.666687 6.24196 0.753153 6.49301 0.903503C6.74407 0.753153 7.03807 0.666687 7.35227 0.666687H10.6479C10.9621 0.666687 11.2561 0.753153 11.5072 0.903503C11.7582 0.753153 12.0522 0.666687 12.3664 0.666687H15.662C16.5852 0.666687 17.3334 1.41289 17.3334 2.33335V15.6667C17.3334 16.5871 16.5852 17.3334 15.662 17.3334H14.0142H13.1904H12.3664C12.0522 17.3334 11.7582 17.2469 11.5072 17.0965C11.2561 17.2469 10.9621 17.3334 10.6479 17.3334H9.00008H8.17623H7.35227C7.03807 17.3334 6.74407 17.2469 6.49301 17.0965C6.24196 17.2469 5.94796 17.3334 5.63376 17.3334H3.98594H3.16209H2.33813C1.41501 17.3334 0.666748 16.5871 0.666748 15.6667V12.3334V9.00002V2.33335ZM4.00951 15.6667H3.98594H3.96238H3.16209H2.33813V14V13.1667H5.63376V14V15.6667H4.00951ZM2.33813 11.5V9.00002V4.00002V2.33335H3.96238H4.00951H5.63376V4.00002V11.5H2.33813ZM3.98594 9.00002C3.53086 9.00002 3.16198 8.63218 3.16198 8.17844V4.8216C3.16198 4.36786 3.53086 4.00002 3.98594 4.00002C4.44092 4.00002 4.8098 4.36786 4.8098 4.8216V8.17844C4.8098 8.63218 4.44092 9.00002 3.98594 9.00002ZM9.02365 15.6667H9.00008H8.97652H8.17623H7.35227V14V13.1667H10.6479V14V15.6667H9.02365ZM7.35227 11.5V9.00002V4.00002V2.33335H8.97652H9.02365H10.6479V4.00002V11.5H7.35227ZM9.00008 9.00002C8.545 9.00002 8.17612 8.63218 8.17612 8.17844V4.8216C8.17612 4.36786 8.545 4.00002 9.00008 4.00002C9.45506 4.00002 9.82394 4.36786 9.82394 4.8216V8.17844C9.82394 8.63218 9.45506 9.00002 9.00008 9.00002ZM14.0142 15.6667H14.0378H15.662V14V13.1667H12.3664V14V15.6667H13.1904H13.9907H14.0142ZM12.3664 9.00002V11.5H15.662V4.00002V2.33335H14.0378H13.9907H12.3664V4.00002V9.00002ZM13.1903 8.17844C13.1903 8.63218 13.5591 9.00002 14.0142 9.00002C14.4692 9.00002 14.8381 8.63218 14.8381 8.17844V4.8216C14.8381 4.36786 14.4692 4.00002 14.0142 4.00002C13.5591 4.00002 13.1903 4.36786 13.1903 4.8216V8.17844Z"
        fill="#969696"
      />
    </svg>
  );
};

export default StorageIcon;
