import React, { useState } from 'react';
import './styles.css';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { CircleTickIcon, CloseIcon } from '../../../Global/icons';
import { GlobalSearchUserType } from '../../../../types/globalSearchDataType';
import ContactCardOptions from '../ContactCardOptions';
import userInitials from '../../../../helpers/userInitial';
import { nonScrappiUsers } from '../../../../containers/MyContactsContainer';
import { formatToTimeZone } from 'date-fns-timezone';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';
import { useHistory } from 'react-router-dom';
import Trust from '../../../Trust';

/*
 * COMPONENT DESCRIPTION
 * This component is getting used as wrapper for contact card,that will be showing information
 * for contacts and invitation of a user ).
 */

interface Proptypes {
  data: GlobalSearchUserType;
  label: string;
  handleInvitation: (
    userName: string,
    isAccepted: boolean,
    isCancelled: boolean
  ) => void;
  loading: boolean;
  handleWithdrawInvitation: (
    email: string,
    phone: string,
    userName: string
  ) => void;
  handleRemoveContact: (userName: string) => void;
  nonScrappiUsers?: nonScrappiUsers;
  selectedUserName?: string;
  setSelectedUserName?: (val: string) => void;
  type: 'non-scrappi-user' | 'recieved-request' | 'sent-request' | 'my-contact';
}

export default function ContactCard(props: Proptypes) {
  const {
    data: {
      is_sent,
      avatar,
      user_name,
      userName,
      display_name,
      email,
      phone,
      collection_count,
      is_trusted,
    },
    label,
    handleInvitation,
    handleWithdrawInvitation,
    handleRemoveContact,
    nonScrappiUsers,
    setSelectedUserName,
    selectedUserName,
    type,
  } = props;

  const user = useSelector((state: ReduxStateType) => state.user);
  const history = useHistory();
  return (
    <div
      className={`contact-card contact-card--${type} ${
        !collection_count ? ' contact-card--no-collection' : ''
      }`}
    >
      <div className="contact-card__main">
        <div className="contact-card__body">
          <CollectionHeaderAvatar
            image={avatar ? avatar : '/dashboard/img/default-avatar.jpg'}
            text={userInitials(display_name)}
            className={`contact-card__avatar ${
              !user_name || userName ? 'contact-card__avatar--cursor' : ''
            }`}
            userName={user_name || userName}
          />
          <div className="contact-card__details">
            <div
              className={`contact-card__details-body ${
                !user_name && !userName ? 'contact-card__details-body--cursor' : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (user_name) {
                  user_name && history.push(`/${user_name}`);
                }

                if (userName) {
                  userName && history.push(`/${userName}`);
                }
              }}
            >
              <div className="contact-card__name">
                {display_name && (
                  <span className="contact-card__user-name">
                    {display_name}
                  </span>
                )}
                {nonScrappiUsers && (
                  <span className="contact-card__user-name">
                    {nonScrappiUsers.email
                      ? nonScrappiUsers.email
                      : nonScrappiUsers.phone}
                  </span>
                )}
                {(user_name || userName) && (
                  <span className="contact-card__user-id">
                    {'@' + (user_name ? user_name : userName)}
                    <Trust
                      isTrusted={is_trusted || label === 'trusts'}
                      view="icon"
                    />
                  </span>
                )}
              </div>
              <div className="contact-card__collection">
                {collection_count ? (
                  <>
                    <span className="contact-card__collection__collection-count">
                      {collection_count}
                    </span>
                    {!nonScrappiUsers && (
                      <span className="contact-card__collection__collection-text">
                        {collection_count > 1 ? 'Collections' : 'Collection'}
                      </span>
                    )}
                  </>
                ) : null}

                {nonScrappiUsers && (
                  <span className="contact-card__collection__collection-text">
                    {nonScrappiUsers &&
                      'sent on ' +
                        formatToTimeZone(
                          nonScrappiUsers.created_at,
                          'MM/DD/YYYY',
                          {
                            timeZone: user.timezone,
                          }
                        )}
                  </span>
                )}
              </div>
            </div>
            {label === 'invitations' && is_sent && (
              <div className="contact-card__invitation-action">
                <button
                  className="contact-card__invitation-action__decline"
                  onClick={() => handleInvitation(user_name, false, true)}
                >
                  <CloseIcon />
                </button>
                <button
                  className="contact-card__invitation-action__accept"
                  onClick={() => handleInvitation(user_name, true, false)}
                >
                  <CircleTickIcon />
                </button>
              </div>
            )}
            {label === 'invitations' && !is_sent && !nonScrappiUsers && (
              <button
                className="contact-card__withdraw"
                onClick={() =>
                  handleWithdrawInvitation(email, phone, user_name)
                }
              >
                {' '}
                Withdraw{' '}
              </button>
            )}
          </div>
        </div>
      </div>
      {label === 'contacts' && (
        <ContactCardOptions
          handleRemoveContact={() => handleRemoveContact(user_name)}
          selectedUserName={selectedUserName}
          userName={user_name}
          setSelectedUserName={(userName) => setSelectedUserName(userName)}
        />
      )}
    </div>
  );
}
