import React, { useState, Dispatch, SetStateAction } from 'react';
import { parseTryCatchError } from '../../../../helpers/parseTryCatchError';
// import './'
import CollectionSettingsPopup from '../../../CollectionSettingsPopup';
import './styles.css';
interface Proptypes {
  isCreating: boolean;
  className?: string;
  onCreate: (
    val: {
      title: string;
      desc?: string;
      cover_image?: string;
      view_type?: number;
    },
    cb: Function,
    onError: (message: string) => void
  ) => void;
  controlled?: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
}
const CreateCollectionModal = (props: Proptypes) => {
  const { isCreating, onCreate, className, controlled } = props;

  const [createErrorMessage, setCreateErrorMessage] = useState<null | string>(
    null
  );
  const createHandler = async (data: {
    title: string;
    desc?: string;
    cover_image?: string;
    view_type?: number;
    sort_by?: string;
    sort_param?: string;
  }) => {
    try {
      await onCreate(
        data,
        () => {},
        (message) => setCreateErrorMessage(message)
      );
    } catch (error) {
      throw new Error(parseTryCatchError(error));
    }
  };
  
  if (controlled) {
    const closeModal = () => {
      setOpen(false);
    };
    const { open, setOpen } = controlled;
    return (
      <CollectionSettingsPopup
        isEditing={isCreating}
        onCollectionEdit={createHandler}
        controlled={{ show: open, setShow: setOpen }}
        createCollection
        openDeletePopup={()=>true}
        state={1}
        selectedSort='Newest'
        view_type={3}
      />
    );
  }
  return (
    <CollectionSettingsPopup
      isEditing={isCreating}
      onCollectionEdit={createHandler}
      triggerer={
        <button
          className={`button button-primary${className ? ' ' + className : ''}`}
        >
          Create a Collection
        </button>
      }
      selectedSort='Custom'
      openOtherFieldsByDefault
    />
  );
};

export default CreateCollectionModal;
