import React, {useEffect, useRef, useState} from 'react';
import { isMobileDevice } from '../../../../helpers';
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_CONTACTS, UNLINK_ACCOUNT} from "./ContactImportQueries";
import XFollowsCard from "./components/XFollowsCard";
import GoogleContactAuth from "./components/GoogleContactAuth";
import { useDispatch } from "react-redux";
import { setWarningPopup } from "../../../../redux/action/utils";

/**
 * GoogleContacts Component
 *
 * This component provides a button to sync Google contacts.
 * The feature is specifically designed for use within a mobile app using WebView.
 *
 * Features:
 * - Detects if the app is running on a mobile device.
 * - Sends a message to a React Native WebView to initiate Google Contacts sync.
 * - Alerts the user if the feature is accessed outside a mobile app environment.
 */

const GoogleContacts = () => {
  const [googleConnections, setGoogleConnections] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [ getContacts ] = useLazyQuery(GET_CONTACTS);

  // pagination states for followers list
  const pageRef = useRef(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [loading, setLoading] = useState(true);
  const [totalContacts, setTotalContacts] = useState(null);

  const dispatch = useDispatch();

  const [ unlinkAccount ] = useMutation(UNLINK_ACCOUNT);
  // Check if the code is running on the server or client-side.
  const isServer = typeof window === 'undefined';

  useEffect(() => {
    getContacts({
      variables: {
        page: 1,
        first: 100,
        service_id: 4,
      },
    })
      .then((resp) => {
        const response = resp.data?.socialTrusts;
        if (!response) {
          setLoading(false);
          setHasMorePages(false);
          return;
        }

        const contactsList = response.data?.socialTrusts || [];
        const pagingInfo = response.paginatorInfo;

        const contacts = contactsList.map((item) => {
          const contact = JSON.parse(item.social_response);
          return {
            ...item,
            ...contact,
            firstName: contact.names ? contact.names[0]?.givenName : null,
            lastName: contact.names ? contact.names[0]?.familyName : null,
          };
        });

        setTotalContacts(pagingInfo?.total || 0);

        setGoogleConnections(contacts);
        setHasMorePages(pagingInfo?.hasMorePages || false);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    const profileWrapper = document.querySelector('.profile__wrapper');
    const scrollHandler = (e: Event) => {
      const { scrollHeight, scrollTop, clientHeight } = profileWrapper;
      if (scrollHeight - scrollTop - 100 > clientHeight || !hasMorePages) {
        return;
      }
      setLoading(true);
      const newPage = pageRef.current + 1;
      pageRef.current = newPage;
      getContacts({
        variables: {
          page: newPage,
          first: 100,
          service_id: 4,
        },
      })
        .then((resp) => {

          const response = resp.data?.socialTrusts;
          if (!response) {
            setLoading(false);
            setHasMorePages(false);
            return;
          }
          const contactsList = response.data?.socialTrusts || [];
          const pagingInfo = response.paginatorInfo;

          const contacts = contactsList.map((item) => {
            const contact = JSON.parse(item.social_response);
            return {
              ...item,
              ...contact,
              firstName: contact.names ? contact.names[0]?.givenName : null,
              lastName: contact.names ? contact.names[0]?.familyName : null,
            };
          });
          setGoogleConnections((prev) => [...prev, ...contacts]);
          setHasMorePages(pagingInfo?.hasMorePages || false);
        })
        .finally(() => setLoading(false));
    };
    profileWrapper.addEventListener('scroll', scrollHandler);

    return () => {
      profileWrapper.removeEventListener('scroll', scrollHandler);
    };
  }, [loading]);

  /**
   * Unlink the LinkedIn account
   * and remove the data
   */
  async function deleteGoogleConnections() {
    setDeleting(true);
    const unlinked = await unlinkAccount({
      variables: {
        service_id: 4,
      },
    });

    setDeleting(false);
    setGoogleConnections([]);
    reloadPage();
  }

  /**
   * Reload the page
   */
  function reloadPage() {
    // if not server refresh the page
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?google-contacts=true';
    }
  }


  /**
   * Handles the "Sync Google Contacts" button click.
   * - Sends a message to the React Native WebView if the environment is a mobile device.
   * - Displays an alert if accessed from a non-mobile or non-WebView environment.
   */
  const syncContactsClickHandler = () => {
    // Ensure the code is running in a browser-like environment in a webview of mobile app, not on the server.
    // @ts-ignore
    if (!isServer && window.ReactNativeWebView && isMobileDevice()) {
      // Send a message to the React Native WebView to initiate contact sync.
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          requestType: 'sync-google-contacts', // Specifies the action type for the React Native handler.
        })
      );
    } else {
      // Inform the user that the feature is mobile app-specific.
      alert('This can only be used from your mobile app');
    }
  };

  // @ts-ignore - ReactNativeWebView is not a property of window and is custom
  const isMobile = !isServer && window.ReactNativeWebView && isMobileDevice()

  return (
    <div className="google-contacts">
      <div className="x-follows__header">
        <h1 className="x-follows__title">Google Contacts</h1>
      </div>

      <div>
        <p className="x-follows__instructions">
          Authenticate with your Google Account and import Contacts from your
          Google Contacts. New users will be automatically Trusted as they join
          Scrappi and existing users will be Trusted now. Refresh this section
          every so often to capture new Contacts.
        </p>

        {/* Button to trigger the contact sync handler */}
        {isMobile && (
          <button
            className="button button__primary button__block"
            onClick={syncContactsClickHandler}
          >
            Click to Sync your Google Contacts
          </button>
        )}

        {!isMobile && <GoogleContactAuth />}

        {googleConnections?.length > 0 && (
          <p>
            <button
              className="button button__secondary button__block"
              onClick={async () => {
                dispatch(
                  setWarningPopup({
                    warningMessage:
                      'Are you sure? Click OK to unlink this Google account.',
                    header: 'Warning',
                    leftLoader:true,
                    cancel: {
                      label: 'Cancel',
                    },
                    submit: {
                      label: 'OK',
                      cb: async () => {
                        await deleteGoogleConnections();
                      },
                    },
                  })
                )
              }}
              disabled={deleting}
            >
              {deleting ? 'Unlinking account' : 'Unlink Google Contacts'}
            </button>
          </p>
        )}
      </div>

      <div className={'x-follows__deleting'}>
        {googleConnections?.length > 0 && (
          <>
            <hr />
            <h3>Your Contacts {totalContacts && `(${totalContacts})`}</h3>
          </>
        )}

        {googleConnections?.length > 0 &&
          googleConnections?.map((user, index) => {
            const { firstName, lastName, service_user_id, description } = user;
            const profileData = user.profile;

            return (
              <div
                className="x-follows__wrapper"
                key={`${index}-${service_user_id}`}
              >
                <XFollowsCard
                  screenName={service_user_id}
                  name={`${firstName || ''} ${lastName || ''}`}
                  description={description}
                  profileData={profileData}
                  hideUser
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GoogleContacts;
