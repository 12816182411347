import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const MyFeedContainer = () => {
  
  useEffect(() => {
    // Override the background color when this page is mounted
    document.body.style.backgroundColor = 'black'; // Set the background color to your desired color

    // Reset the background color when the component is unmounted
    return () => {
      document.body.style.backgroundColor = ''; // This will remove the override
    };
  }, []); // Empty dependency array to run only once when the component mounts

  const htmlNewsContent = `
  <rssapp-magazine id="_93n0sjhYN3aXsxuP"></rssapp-magazine>
  `;
  const htmlBusinessContent = `
    <rssapp-magazine id="_slNVg8GJjgzvvmTg"></rssapp-magazine>
  `;
  const htmlTechContent = `
    <rssapp-magazine id="_9Yqw1IYJYB9TcWlK"></rssapp-magazine>
  `;
  const htmlSportsContent = `
    <rssapp-magazine id="_IskbnfY5mzSyn2nr"></rssapp-magazine>
  `;

  useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://widget.rss.app/v1/magazine.js';
  script.async = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
  }, []);
 
  return (
    <div className="feed-container">
      <div className="logo">
        <img src="dashboard/img/Group.svg" height="30px" alt="Logo" />
      </div>
      <div className="tabs-container">
      <Tabs>
        <TabList>
          <Tab>News</Tab>
          <Tab>Business</Tab>
          <Tab>Tech / AI</Tab>
          <Tab>Sports</Tab>
        </TabList>

        <TabPanel>
          <div dangerouslySetInnerHTML={{ __html: htmlNewsContent }} />
        </TabPanel>

        <TabPanel>
          <div dangerouslySetInnerHTML={{ __html: htmlBusinessContent }} />
        </TabPanel>

        <TabPanel>
          <div dangerouslySetInnerHTML={{ __html: htmlTechContent }} />
        </TabPanel>

        <TabPanel>
          <div dangerouslySetInnerHTML={{ __html: htmlSportsContent }} />
        </TabPanel>

      </Tabs>
      </div>
    </div>
  );
};

export default MyFeedContainer;
