import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface propTypes {
  icon: React.ReactNode;
  label: string;
  link?: string;
  customClass?: string;
  onClick?: () => void;
}

const MobileNavigationButton = ({
  icon,
  label,
  customClass,
  link,
  onClick,
}: propTypes) => {
  const { pathname } = useLocation();
  let isActive = pathname.startsWith(link);

  // collections URL has /c in the patch, so account for it
  if (link === '/collections') {
    isActive = pathname.startsWith(link) || pathname.startsWith('/c');
  }

  if (link === '/') {
    isActive = pathname === link;
  }

  if (onClick) {
    return (
      <div
        onClick={onClick}
        className={`mobile-nav__button ${customClass || ''}${
          isActive ? ' active' : ''
        }`}
      >
        <span className="mobile-nav__icon">{icon}</span>
        <span className="mobile-nav__label">{label}</span>
      </div>
    );
  }

  return (
    <Link
      to={link}
      className={`mobile-nav__button ${customClass || ''}${
        isActive ? ' active' : ''
      }`}
    >
      <span className="mobile-nav__icon">{icon}</span>
      <span className="mobile-nav__label">{label}</span>
    </Link>
  );
};

export default MobileNavigationButton;
