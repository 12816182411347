import React, { useEffect } from 'react';
import * as config from '../../../../settings.json';
import { useLazyQuery, useMutation } from '@apollo/client';

import './ContactImportStyles.css';
import XFollowsCard from './components/XFollowsCard';
import NewPopup from '../../../Global/NewPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { EDIT_PROFILE } from '../../../../containers/data/editProfile';
import {
  AUTO_TRUST,
  GET_CONTACTS,
  SAVE_USER_SOCIAL_INFO,
  UNLINK_ACCOUNT,
  UPSERT_CONTACTS,
} from './ContactImportQueries';
import { setWarningPopup } from "../../../../redux/action/utils";
import { useDispatch } from "react-redux";

const faSpinnerIcon = faSpinner as IconProp;

const ContactImport = ({ socialMediaData }: { socialMediaData: string }) => {
  const isServer = typeof window === 'undefined';
  const [loading, setLoading] = React.useState(false);
  const [following, setFollowing] = React.useState([]);
  const [gettingFollows, setGettingFollows] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [totalFollows, setTotalFollows] = React.useState(0);
  const [importedFollows, setImportedFollows] = React.useState(0);
  const [totalContacts, setTotalContacts] = React.useState(null);

  const dispatch = useDispatch();

  // create a lazy load query
  const [getFollows] = useLazyQuery(GET_CONTACTS);

  // pagination states for followers list
  const pageRef = React.useRef(1);
  const [hasMorePages, setHasMorePages] = React.useState(true);

  // save the user's social information to the database
  const [upsertSocialSync] = useMutation(SAVE_USER_SOCIAL_INFO);

  // create a mutation to save the list of follows
  const [upsertContacts] = useMutation(UPSERT_CONTACTS);

  const [upsertSocialUserTrusts] = useMutation(AUTO_TRUST);

  // save user's X information to social media data
  const [upsertSocialMediaData] = useMutation(EDIT_PROFILE);

  // unlink X account
  const [deleteSocialSync] = useMutation(UNLINK_ACCOUNT);

  // get the list of followers and user data
  interface bodyType {
    token?: object;
    nextCursor?: string;
    hasMoreUsers?: boolean;
  }

  async function saveFollowers(
    code: string,
    state: string,
    body: bodyType = null
  ) {
    return fetch(`/api/twitter?code=${code}&state=${state}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.error) {
          setError(true);
          setErrorMessage(data.error)
          return null;
        }
        else {
          setError(false);
          setErrorMessage(null)
          return data;
        }
      })
      .catch((err) => {
        setError(true);
        console.error('err', err);
        return null;
      });
  }

  /**
   * The user's followers comes in a batch of 50 users
   * We need to make a request to the X API to get the next 50 users
   * and save them to the database
   * the follows key has more_users key which is true if there are more users to fetch
   * and if the value is true, we use the next_cursor key to get the next 50 users
   * loop through the saveFollowers function until there are no more users to fetch
   */
  async function getListOfFollows(code: string, state: string) {
    let nextCursor = null;
    let firstLoad = true;
    let hasMoreUsers = false;
    let token = null;
    while (hasMoreUsers || firstLoad) {
      firstLoad = false;
      await saveFollowers(code, state, {
        token,
        nextCursor,
        hasMoreUsers,
      }).then((res) => {
        const listOfFollows = res?.follows?.following;
        const userData = res?.user;

        // set the total number of follows from the profile data
        const totalFollows = userData?.public_metrics?.following_count || 0;
        setTotalFollows(totalFollows);

        if (userData && listOfFollows) {
          saveUserSocials(userData);
          setFollowing((existingFollowing) => [
            ...existingFollowing,
            ...listOfFollows,
          ]);

          // every time we get and save 50 follows, we increment the imported follows
          // make sure the number doesn't exceed the total number of follows
          setImportedFollows((importedFollows) =>
            Math.min(totalFollows, importedFollows + 50)
          );

          saveContacts(listOfFollows);
          nextCursor = res?.follows?.next_cursor;
          hasMoreUsers = res?.follows?.more_users || false;
          token = res?.user?.token;
        }
      });
    }

    // auto trust the users
    await upsertSocialUserTrusts({
      variables: {
        service_id: 1,
      },
    });

    setGettingFollows(false);

    setLoading(false);
  }

  // when the component loads and there is code parameter in the URL, we will exchange it for an access token
  // get the values the code and make a request to '/api/twitter' to exchange it for an access token
  React.useEffect(() => {
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (!code || !state) return;
    setGettingFollows(true);
    getListOfFollows(code, state).finally(() => {
      setGettingFollows(false);
      reloadPage();
    });
  }, []);

  function reloadPage() {
    // if not server refresh the page
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?x-follows=true';
    }
  }

  /**
   * If the page comes after redirecting from X, then get the list of follows
   */
  useEffect(() => {
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    // callback from twitter, no need to fetch the user's follows
    if (code && state) {
      return;
    }

    // get user's follows
    setLoading(true);
    getFollows({
      variables: {
        first: 100,
        page: 1,
        service_id: 1,
      },
    })
      .then((res) => {
        const response = res?.data?.socialTrusts;
        const pagingInfo = response?.paginatorInfo;
        setTotalContacts(pagingInfo?.total || 0);

        if (!response) {
          setHasMorePages(false);
          setLoading(false);
          return;
        }

        // parse the social_response key to display the user details and update the follow list
        const following = response?.data?.socialTrusts.map((item) => {
          try {
            const follow = JSON.parse(item.social_response);
            return {
              ...follow,
              profile: item.profile,
            };
          } catch (error) {
            return {};
          }
        });

        if (following.length) {
          setFollowing(following);
        }
        setHasMorePages(pagingInfo?.hasMorePages || false);
      })
      .finally(() => setLoading(false));
  }, []);

  /**
   * useEffect for handling scroll event to load more followers
   */
  useEffect(() => {
    if(loading) return;

    const profileWrapper = document.querySelector('.profile__wrapper');
    const scrollHandler = (e: Event) => {

      const { scrollHeight, scrollTop, clientHeight } = profileWrapper;
      if (scrollHeight - scrollTop - 100 > clientHeight || !hasMorePages) {
        return;
      }
      setLoading(true);
      const newPage = pageRef.current + 1
      pageRef.current = newPage;
      getFollows({
        variables: {
          first: 50,
          page: newPage,
          service_id: 1,
        },
      })
        .then((res) => {
          const response = res?.data?.socialTrusts;
          const pagingInfo = response?.paginatorInfo;
          if (!response) {
            setLoading(false);
            setHasMorePages(false);
            return;
          }

          // parse the social_response key to display the user details and update the follow list
          const following = (response.data?.socialTrusts || []).map((item) => {
            try {
              const follow = JSON.parse(item.social_response);
              return {
                ...follow,
                profile: item.profile,
              };
            } catch (error) {
              return {};
            }
          });

          if (following.length) {
            setFollowing(prev => [...prev, ...following]);
          }
          setHasMorePages(pagingInfo?.hasMorePages || false);
        })
        .finally(() => setLoading(false));

    }
    profileWrapper.addEventListener('scroll', scrollHandler);

    return () => {
      profileWrapper.removeEventListener('scroll', scrollHandler);
    }

  }, [loading]);

  async function saveUserSocials(userData = null) {
    const res = await upsertSocialSync({
      variables: {
        service_id: 1,
        service_response: JSON.stringify(userData),
        service_user_id: userData.id,
        service_username: userData.username,
      },
    });
    // save the X information in sync table
    const response = res?.data?.upsertSocialSync?.data;
    if (!response && !userData.username) return;
    // save the user's X information to social media data
    const xUsername = userData.username;
    const updatedSocialData = parseSocialMediaData(socialMediaData, xUsername);
    await upsertSocialMediaData({
      variables: {
        social_media_accounts: updatedSocialData,
      },
    });
  }

  /**
   * parse user's social media data, add or update X information
   * @param data - existing social media data
   * @param username - X username that we got from authentication
   * @returns string
   */
  function parseSocialMediaData(data: string, username: string) {
    try {
      const parsedData = JSON.parse(JSON.parse(data));
      parsedData['Twitter'] = username;
      return JSON.stringify(parsedData);
    } catch (error) {
      console.error('error parsing social media data', error);
      return data;
    }
  }

  function saveContacts(data = null) {
    const followingList = data ? data : following;

    const social_trusts = followingList.map((item) => ({
      service_id: 1,
      service_user_id: item.user_id,
      service_username: item.screen_name,
      social_response: JSON.stringify(item),
    }));
    upsertContacts({
      variables: {
        social_trusts,
      },
    }).then((res) => {
      const response = res?.data?.upsertSocialTrusts;
      if (!response) return;
    });
  }

  /**
   * Unlink X account
   */
  async function unlinkXAccount() {
    setDeleting(true);
    const service_id = 1;
    await deleteSocialSync({
      variables: {
        service_id,
      },
    });
    setDeleting(false);
    // window check
    // and reload the page to '/profile?x-follows=true'
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?x-follows=true';
    }
  }

  /**
   * Redirect to twitter to get the access token
   */
  function redirectToTwitter() {
    const clientId = config.twitterClientId;
    const redirectUri = `${config.appUrl}/profile`;
    const scopes = ['tweet.read', 'users.read', 'follows.read']; // Adjust as needed

    // Generate a code verifier and code challenge (using PKCE)
    const codeChallenge = 'challenge';

    // Step 1: Construct the authorization URL
    const authorizationUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
      ' '
    )}&state=state&code_challenge=${codeChallenge}&code_challenge_method=plain`;

    // redirect to the authorization URL
    window.location.href = authorizationUrl;
  }

  return (
    <div className="x-follows">
      <div className="x-follows__header">
        <h1 className="x-follows__title">X Follows</h1>

        {following?.length > 0 && (
          <button
            className="button button__secondary"
            onClick={redirectToTwitter}
          >
            {error ? 'Try Again' : 'Refresh Follows'}
          </button>
        )}
      </div>

      <div>
        <p className="x-follows__instructions">
          Connect your <strong>X Account</strong> and create{' '}
          <strong>Trusted Sources</strong> with your Follows automatically. If
          they are on Scrappi already they'll be Trusted now. Or, as they join
          Scrappi they'll automatically be added as Trusted. As you add more
          Follows on X, be sure to refresh this section to capture new Follows.
        </p>

        {!gettingFollows && following?.length !== 0 && (
          <p>
            <button
              className="button button__secondary button__block"
              onClick={() => {
                dispatch(
                  setWarningPopup({
                    warningMessage:
                      'Are you sure? Click OK to unlink this X account.',
                    header: 'Warning',
                    leftLoader:true,
                    cancel: {
                      label: 'Cancel',
                    },
                    submit: {
                      label: 'OK',
                      cb: async () => {
                        await unlinkXAccount();
                      },
                    },
                  })
                )
              }}
              disabled={deleting}
            >
              Unlink X Account
            </button>
          </p>
        )}
      </div>

      {error && (
        <div className="x-follows__error">
          <p>{errorMessage ? errorMessage : 'Something went wrong. Please try again.'}</p>
        </div>
      )}

      {loading && !following.length ? (
        <div className="x-follows__loading">
          <span>Loading X follows...</span>
        </div>
      ) : null}

      {following?.length === 0 && !loading && (
        <button className="button button__primary" onClick={redirectToTwitter}>
          Connect X
        </button>
      )}

      {gettingFollows && (
        <NewPopup defaultOpen size="Small">
          <div className="x-follows__importing">
            <h2>Importing your X Follows</h2>

            <div className="x-follows__importing-spinner">
              <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
              <span>
                {' '}
                Imported {importedFollows}/{totalFollows} Follows
              </span>
            </div>

            <p>
              Please wait while your X follows are imported. This may take a few
              moments depending on the number of users you follow on X. Please
              do not navigate away from this screen until the import is
              complete.
            </p>
          </div>
        </NewPopup>
      )}

      <div className={deleting ? 'x-follows__deleting' : ''}>
        {following.length > 0 && (
          <>
            <hr />
            <h3>Your LinkedIn Connections {totalContacts && `(${totalContacts})`}</h3>
          </>
        )}

        {following?.length > 0 &&
          following?.map((user, index) => {
            const { screen_name, profile_image, name, description } = user;
            const profileData = user.profile;

            return (
              <div
                className="x-follows__wrapper"
                key={`${index}-${screen_name}`}
              >
                <XFollowsCard
                  screenName={screen_name}
                  profileImage={profile_image}
                  name={name}
                  description={description}
                  profileData={profileData}
                  link={`https://twitter.com/${screen_name}`}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ContactImport;
