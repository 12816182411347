import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './styles.css';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EditPublicProfileForm from '../components/EditPublicProfileForm';
import SubscriptionComponent from '../components/Subscription';
import { userTypes } from '../../../types/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import UploadFile from '../../UploadFile/uploadFile';
import { returnImageURL } from '../../Gallery/helpers/returnImageURL';
import ResponsiveImage from '../../Global/ResponsiveImage';
import {
  PenEditIcon,
  PencilEditIcon,
  NewGlobeIcon,
  Back,
  LoginIcon,
  Google,
  ContactsIcon,
  StorageIcon
} from '../../Global/icons';
import { returnShareLink } from '../../../helpers/returnShareLinks';
import TrashOutline from '../../Global/icons/TrashOutline';
import DeleteAccount from '../components/DeleteAccount';
import NewGlobe from '../../Global/icons/NewGlobe';
import MyAccount from '../components/MyAccount';
import useWindowResize from '../../../helpers/customHooks/useWindowResize';
import { Link } from 'react-router-dom';
import LoginMethod from '../components/LoginMethod';
import ContactImport from './ContactImport';
import LinkedInContacts from "./ContactImport/LinkedInContacts";
import GoogleContacts from './ContactImport/GoogleContacts';
import DeviceContacts from './ContactImport/DeviceContacts';

const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  onProfileSubmit: (data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    country?: string;
    timezone?: string;
    headline?: string;
    bio?: string;
  }) => Promise<string>;
  onNewPasswordSubmit: (variables: {
    old_password: string;
    password: string;
    password_confirmation?: string;
  }) => Promise<string>;
  onImageUpload: (url: string) => Promise<void>;
}

const Profile = (props: Proptypes) => {
  const { width } = useWindowResize();
  const { search } = useLocation();
  const { replace, push } = useHistory();
  const setDefault = () => {
    let param = qs.parse(search.substring(1));
    if (width <= 960) {
      if (param['login-method'] === 'true') {
        return 'login-method';
      }
      return 'my-account';
    } else {
      return 'edit-profile';
    }
  };

  const { onProfileSubmit, onImageUpload } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userData = useSelector((state: any) => state.user as userTypes);
  const [formState, setFormState] = useState<
    | 'edit-profile'
    | 'change-password'
    | 'login-method'
    | 'subscription'
    | 'storage'
    | 'delete-account'
    | 'my-account'
    | 'import-contacts'
    | 'ln-contacts'
    | 'device-contacts'
    | 'google-contacts'
  >(setDefault());

  const { pathname, search: comingFrom } = useLocation();

  const [uploadingImage, setUploadingImage] = useState(false);

  const isUserDefined = Object.keys(userData).length > 1;

  //dropdown list for mobile view
  const DropdownArrayList = [
    {
      label: 'Edit Profile',
      stateName: 'edit-profile',
      description:
        'Update account information such as your avatar, phone number, email, and username.',
      icon: <PencilEditIcon className="profile__wrapper-icon" />,
      cb: () => {
        setFormState('edit-profile');
        replace(`?edit-profile=true`);
      },
    },
    // {
    //   label: 'Reset Password',
    //   stateName: 'change-password',
    //   description: 'Update your login password.',
    //   icon: <Key className="key-icon" />,
    //   cb: () => setFormState('change-password'),
    // },
    {
      label: 'Login',
      stateName: 'login-method',
      icon: <LoginIcon />,
      cb: () => {
        setFormState('login-method');
        replace(`?login-method=true`);
      },
    },
    {
      label: 'Subscription',
      stateName: 'storage',
      description: "Check out your subscription options on Scrappi.",
      icon: <StorageIcon />,
      cb: () => {
        setFormState('storage');
        replace(`?show-storage=true`);
      },
    },
    {
      label: 'View Public Profile',
      stateName: 'public-profile',
      description:
        'See how your profile page is viewed by Scrappers everywhere.',
      icon: <NewGlobeIcon />,
      cb: () => push(`/${userData.userName}`),
    },
    {
      label: 'X Follows',
      stateName: 'import-contacts',
      description: 'Import your contacts from other platforms.',
      icon: <NewGlobeIcon />,
      cb: () => {
        setFormState('import-contacts')
        replace('/profile?x-follows=true')
      },
    },
    {
      label: 'LinkedIn Connections',
      stateName: 'ln-contacts',
      description: 'Import your contacts from other platforms.',
      icon: <NewGlobeIcon />,
      cb: () => {
        setFormState('ln-contacts');
        replace('/profile?ln-contacts=true');
      },
    },
    {
      label: 'Device Contacts',
      stateName: 'ln-contacts',
      description: 'Import your contacts from your mobile device.',
      icon: <ContactsIcon />,
      cb: () => {
        setFormState('device-contacts');
        replace('/profile?device-contacts=true');
      },
    },
    {
      label: 'Google Contacts',
      stateName: 'ln-contacts',
      description: 'Import your contacts from your google account.',
      icon: <Google />,
      cb: () => {
        setFormState('google-contacts');
        replace('/profile?google-contacts=true')
      },
    },
    {
      label: 'Delete Account',
      stateName: 'delete-account',
      description: 'Remove your account and data from Scrappi.',
      icon: <TrashOutline />,
      cb: () => setFormState('delete-account'),
    },
  ];

  const returnTitle = () => {
    switch (formState) {
      case 'edit-profile':
        return 'Edit Profile';
      case 'change-password':
        return 'Reset Password';
      case 'subscription':
        return 'Subscription';
      case 'delete-account':
        return 'Delete Account';
    }
  };

  const returnelement = () => {
    if (width <= 960 && formState !== 'my-account') {
      return (
        <div className="my-account__header">
          <span
            onClick={() => setFormState('my-account')}
            className="my-account__back-icon"
          >
            <Back />
          </span>
          <h2 className="my-account__header__title">{returnTitle()}</h2>
        </div>
      );
    } else {
      return (
        <h1 className="profile__wrapper-title">
          {formState === 'edit-profile' && width <= 960
            ? 'Edit Profile'
            : 'My Account'}
        </h1>
      );
    }
  };
  useEffect(() => {
    if (width <= 960) {
      let param = qs.parse(search.substring(1));
      const isServer = typeof window === 'undefined';
      if (isServer) return null;

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      // callback from twitter
      if (code && state) {
        setFormState('import-contacts');
      }
      if (param['login-method'] === 'true') {
        setFormState('login-method');
      }
      if (param['x-follows'] === 'true') {
        setFormState('import-contacts');
      }
      if (param['ln-contacts'] === 'true') {
        setFormState('ln-contacts');
      }
      setFormState('my-account');
    } else if (width > 960 && formState !== 'my-account') {
      setFormState(formState);
    } else {
      setFormState('edit-profile');
    }
  }, [width]);
  useEffect(() => {
    let param = qs.parse(search.substring(1));
    const isServer = typeof window === 'undefined';
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    // callback from twitter
    if(code && state && state === 'ln-connect') {
      setFormState('ln-contacts');
    }
    if (code && state && state !== 'ln-connect') {
      setFormState('import-contacts');
    }
    if (param['login-method'] === 'true') {
      setFormState('login-method');
    }
    if (param['x-follows'] === 'true') {
      setFormState('import-contacts');
    }
    if (param['ln-contacts'] === 'true') {
      setFormState('ln-contacts');
    }
    if (param['device-contacts'] === 'true') {
      setFormState('device-contacts');
    }
    if (param['google-contacts'] === 'true') {
      setFormState('google-contacts');
    }
    // if (param['show-storage'] === 'true') {
    //   setFormState('storage');
    if (param['show-subscription'] === 'true') {
      setFormState('subscription');
    }
    if (param['my-account'] === 'true') {
      if (width <= 960) {
        setFormState('my-account');
      } else {
        setFormState('edit-profile');
      }
    }
    if (param['edit-profile'] === 'true') {
      setFormState('edit-profile');
    }
  }, [search]);

  const returnForm = () => {
    if (!isUserDefined)
      return (
        <div className="profile-form profile-form__loading">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="3x" />
        </div>
      );
    switch (formState) {
      case 'edit-profile':
        return <EditPublicProfileForm onSubmit={onProfileSubmit} />;
      case 'login-method':
        return <LoginMethod />;

        // uncomment this for reset password
        // case 'change-password':
        //   return <ResetPasswordForm onSubmit={onNewPasswordSubmit} />;

      case 'import-contacts':
        return (
          <ContactImport socialMediaData={userData.social_media_accounts}/>
        );

      case 'ln-contacts':
        return <LinkedInContacts socialMediaData={userData.social_media_accounts} />;

      case 'device-contacts':
        return <DeviceContacts />;

      case 'google-contacts':
        return <GoogleContacts />

      // case 'storage':
      //   return <StorageComponent />;
      case 'subscription':
        return <SubscriptionComponent />;
      case 'delete-account':
        return <DeleteAccount />;
    }
  };
  async function handleImageUpload(imageUrl) {
    try {
      await onImageUpload(imageUrl);
    } catch (error) {
      alert((error as { message: string }).message || 'Something went wrong');
    }
    setUploadingImage(false);
  }
  const USER_AVATAR = userData?.avatar
    ? `${returnImageURL(userData.avatar)}`
    : '/dashboard/img/default-avatar.jpg';

  let shareLink = returnShareLink('user', '', userData.userName, true);
  shareLink += '?from=' + encodeURIComponent(pathname + comingFrom);

  return (
    <>
      {formState === 'my-account' ? (
        <div className="profile__wrapper-dropdown__list">
          {userData.userName && (
            <MyAccount list={DropdownArrayList} formState={formState} />
          )}
        </div>
      ) : (
        <div className="profile">
          <Helmet>
            <title>Scrappi | My Profile</title>
          </Helmet>

          <div className="profile__wrapper">
            <div className="profile__wrapper-action">
              <div className="profile__wrapper-action-image-container">
                {returnelement()}
                <div className="profile__wrapper-action-image">
                  {uploadingImage && (
                    <div className="profile__wrapper-action-image--uploading">
                      <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
                    </div>
                  )}
                  {userData?.avatar ? (
                    <ResponsiveImage
                      imageClass="preview-image lazy"
                      sizes={['800']}
                      image={USER_AVATAR}
                    />
                  ) : (
                    <img
                      alt="profile_image"
                      className="preview-image lazy"
                      src={USER_AVATAR}
                    />
                  )}
                  <UploadFile
                    isProfileImage
                    aspect={1}
                    onUploadStart={() => setUploadingImage(true)}
                    callback={handleImageUpload}
                    renderDropBox={(getRootProps, getInputProps) => (
                      <span
                        {...getRootProps()}
                        title="Edit Profile"
                        className="action-image action-image--clickable hoverState-button"
                      >
                        <input {...getInputProps()} disabled={uploadingImage} />
                        <PenEditIcon />
                      </span>
                    )}
                    isCircular
                  />
                </div>
                <div>
                  <h1 className="profile__wrapper-title">
                    {(userData.display_name || '').trim()}
                  </h1>
                  <h1 className="profile__wrapper-title-username">
                    @{userData.userName}
                  </h1>
                </div>
              </div>
              <div className="profile__wrapper-action-button-container">
                {/* DropDownList component is only for mobile view */}
                {/* <DropDownList
                    className="profile__wrapper-dropdown__list"
                    formState={formState}
                    list={DropdownArrayList}
                    selectedLabel="Edit Profile"
                    selectedIcon={PencilEditIcon className="profile__wrapper-icon" />}
                />*/}
                <button
                  className={`button button__outline hoverState-text-svg ${
                    formState === 'edit-profile' ? ' focused' : ''
                  }`}
                  onClick={() => {
                    setFormState('edit-profile');
                    replace(`?`);
                  }}
                >
                  <PencilEditIcon className="profile__wrapper-icon"/>
                  Edit Profile
                </button>
                <button
                  className={`button button__outline hoverState-text-svg${
                    formState === 'login-method' ? ' focused' : ''
                  } button__login-method`}
                  onClick={() => {
                    replace('/profile?login-method=true')
                    setFormState('login-method');
                    // replace(`?`);
                  }}
                >
                  <LoginIcon className="login_wrapper-icon"/>
                  Log In
                </button>

                <button
                  className={`button button__outline hoverState-text-svg${
                    formState === 'import-contacts' ? ' focused' : ''
                  } button__login-method`}
                  onClick={() => {
                    replace('/profile?x-follows=true')
                    setFormState('import-contacts');
                  }}
                >
                  <LoginIcon className="login_wrapper-icon"/>
                  X Follows
                </button>

                <button
                  className={`button button__outline hoverState-text-svg${
                    formState === 'ln-contacts' ? ' focused' : ''
                  } button__login-method`}
                  onClick={() => {
                    replace('/profile?ln-contacts=true')
                    setFormState('ln-contacts');
                  }}
                >
                  <LoginIcon className="login_wrapper-icon"/>
                  LinkedIn Connections
                </button>
                <button
                  className={`button button__outline hoverState-text-svg${
                    formState === 'device-contacts' ? ' focused' : ''
                  } button__login-method`}
                  onClick={() => {
                    replace('/profile?device-contacts=true')
                    setFormState('device-contacts');
                  }}
                >
                  <ContactsIcon className="login_wrapper-icon"/>
                  Device Contacts
                </button>
                <button
                  className={`button button__outline hoverState-text-svg${
                    formState === 'google-contacts' ? ' focused' : ''
                  } button__login-method`}
                  onClick={() => {
                    replace('/profile?google-contacts=true')
                    setFormState('google-contacts');
                  }}
                >
                  <Google className="login_wrapper-icon"/>
                  Google Contacts
                </button>
                {/* Uncomment the following line if you want to enable reset password functionality */}
                {/* <button
                  className={`button button__outline${
                    formState === 'change-password' ? ' focused' : ''
                  }`}
                  onClick={() => {
                    setFormState('change-password');
                    replace(`?`);
                  }}
                >
                  <Key className="key-icon" />
                  Reset Password
                </button> */}

                <button
                  className={`button button__outline hoverState-text-svg ${
                    formState === 'storage' ? ' focused' : ''
                  }`}
                  onClick={() => {
                    setFormState('storage');
                    replace(`?show-storage=true`);
                  }}
                >
                  <StorageIcon />
                  Subscription
                </button>

                <button
                  className={`button button__outline hoverState-text-svg`}
                  onClick={() => push(shareLink)}
                  disabled={!userData?.userName}
                >
                  <NewGlobe/>
                  View Public Profile
                </button>
                <button
                  className={`button button__outline delete-icon hoverState-text-svg-stroke ${
                    formState === 'delete-account' ? ' focused' : ''
                  }`}
                  onClick={() => {
                    setFormState('delete-account');
                    replace(`?`);
                  }}
                >
                  <TrashOutline
                    className={
                      formState === 'delete-account' ? 'trash-icon-color' : ''
                    }
                  />
                  Delete Account
                </button>
              </div>
            </div>

            <div className="profile__wrapper-form">
              <div className="card">
                <div className="card-body">{returnForm()}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
