/* eslint-disable import/no-anonymous-default-export */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { filterItemType } from '../../../containers/SideNavContainer';
import Icon from '../../Global/Icon';
import { BookmarkIcon, CollectionIcon, MyScrapsIcon, Nut, UncategorizedIcon } from '../../Global/icons';
import { Collaborator } from '../../Global/icons';

import './styles.css';
import { GroupType } from '../../../containers/GroupShareContainer/types';
import { useDispatch, useSelector } from "react-redux";
import { ReduxStateType } from '../../../redux/store';
import { canPaste } from "../../../redux/action/pasteDataAction";
const faPlusSquareIcon = faSquarePlus as IconProp;
interface Proptypes<T> {
  isGroupList?: boolean;
  children: (filterItem: T) => React.ReactChild;
  list: T[];
  placeholder?: string;
  valueField: keyof T; // Property name in item object of list, which will be used as a unique value for that item
  labelField: keyof T; // Property name in item object of list, which will be used  to do searching
  createConfig?: {
    buttonLabel: string;
    onClick: () => void;
  };
  notSearchable?: boolean;
  onClick?: (label: filterItemType['label']) => void;
  onCreate?: (
    val: {
      title: string;
      desc?: string;
      cover_image?: string;
      view_type?: number;
    },
    cb: Function,
    onError: (message: string) => void
  ) => void;
  scrollable?: boolean;
  loader?: boolean;
  setLoader?: (boolean) => void;
  showUncategorized?: boolean;
  showMyScraps?: boolean;
  showSavedScraps?: boolean;
  showCollections?: boolean;
  showSavedCollections?: boolean;
  shoCollaborations?: boolean;
  showFull?: boolean;
  creatable?: boolean;
  loadMore?: () => void;
  shareGroupStatus?: string;
  label?: string;
  loading?:boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function <itemType extends { [index: string]: any }>({
  isGroupList,
  children,
  list,
  placeholder,
  valueField,
  labelField,
  createConfig,
  notSearchable,
  onClick,
  onCreate,
  scrollable,
  loader,
  setLoader,
  showUncategorized,
  showCollections,
  showMyScraps,
  showSavedScraps,
  shoCollaborations,
  showSavedCollections,
  showFull,
  creatable,
  loadMore,
  shareGroupStatus,
  label,
  loading
}: Proptypes<itemType>) {
  const user = useSelector((state: ReduxStateType) => state.user);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createErrorMessage, setCreateErrorMessage] = useState<null | string>(
    null
  );
  const faSpinnerIcon = faSpinner as IconProp;
  // const [fullView, setFullView] = useState(showFull);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState(list);
  const { pathname, search } = useLocation();
  // const minItemsSize = showUncategorized ? minlistSize - 1 : minlistSize;
  let listToShow = filteredList;
  // const canToggle = listToShow.length > minItemsSize;
  // if (canToggle && !fullView) {
  //   listToShow = filteredList.slice(0, minItemsSize);
  // }

  const ref = useRef<HTMLDivElement>();
  const dispatch = useDispatch();

  const handleScroll = () => {
    if (label !== 'Shares') return;
    var element = ref.current;
    const { scrollHeight, scrollTop, clientHeight } = element;
    if (scrollHeight - scrollTop - 100 <= clientHeight) {
      if (!loading) {
        loadMore();
      }
    }
  };

  // THIS USEEFFECT TAKES CARE OF LOADING MORE IF THE PAGE IS NOT SCROLLABLE YET FOR ONSCROLL EVENT TO GET FIRED
  useEffect(() => {
    if (ref?.current && shareGroupStatus !== 'pending') {
      const element = ref.current;

      const { scrollTop } = element;

      if (scrollTop === 0) {
        handleScroll();
      }
    }
  }, [ref, list, shareGroupStatus]);

  const createCollection = async (searchTerm) => {
    let variables = {
      title: searchTerm,
      view_type: 3,
    };
    try {
      setLoader(true);
      await onCreate(
        variables,
        () => {},
        (message) => setCreateErrorMessage(message)
      );
      setSearchTerm('');
    } catch (error) {
      alert(error);
    }
  };

  const createOption = () => {
    if (
      list.filter(
        (at) =>
          at?.title?.toLowerCase().trim() === searchTerm?.toLowerCase().trim()
      ).length ||
      !searchTerm.length
    ) {
      return null;
    }
    // RETURN AN ITEM TO BE ABLE TO CREATE A NEW Collection
    return (
      <div
        className="side-nav-dropdown__list-item-label-container"
        onClick={() => createCollection(searchTerm)}
      >
        <FontAwesomeIcon icon={faPlusSquareIcon} />
        <p className="side-nav-dropdown__list-item-label checkbox-list__new-item--label">
          Create
          <i className="checkbox-list__create-quotation">"</i>
          <span className="checkbox-list__new-item--label_tag">
            {searchTerm.trim()}
          </span>
          <i className="checkbox-list__create checkbox-list__create-quotation">
            "
          </i>
        </p>
      </div>
    );
  };

  function returnLabel(item: itemType) {
    let label = item[labelField] as string || '';
    if(isGroupList) {
      // @ts-ignore
      const groupItem = item as GroupType
      let count = groupItem?.members?.length > 1 ? groupItem?.members.length + 1 : null;
      // @ts-ignore
      label = groupItem?.name;
      if (!label) {
        if (groupItem?.nickname && count) label = groupItem?.nickname;
        else {
          if (!count) {
            if (groupItem?.owner_user_name !== user.userName)
              label = groupItem?.owner_display_name ?? groupItem?.owner_user_name;
            else
              label =
                groupItem?.members[0]?.display_name ??
                groupItem?.members[0]?.user_name;
          } else
            label = groupItem?.members
              ?.map((obj) => obj['display_name'] ?? obj['user_name'])
              .join(' ');
        }
          
      }
    }
    return (label || '')
      .trim()
      .toLocaleLowerCase()
      
  }
  function searchHandler(
    text: string,
    itemsList: itemType[],
    toggleList = false
  ) {
    let val = text?.trim() || '';
    if (val) {
      // filter out the list using filter method of array
      setFilteredList(
        itemsList
          .filter((item) =>{
            const result = returnLabel(item).includes(text.toLocaleLowerCase())
            return result
          })
          .sort((a, b) => {
            // Sort results by matching name with text(searched term) position in list item label fields
            if (
              returnLabel(a).toLowerCase().indexOf(text.toLowerCase()) >
              returnLabel(b).toLowerCase().indexOf(text.toLowerCase())
            ) {
              return 1;
            } else if (
              returnLabel(a).toLowerCase().indexOf(text.toLowerCase()) <
              returnLabel(b).toLowerCase().indexOf(text.toLowerCase())
            ) {
              return -1;
            } else {
              if (returnLabel(a) > returnLabel(b)) {
                return 1;
              } else {
                return -1;
              }
            }
          })
      );
    } else {
      setFilteredList(itemsList);
    }
    // toggleList && setFullView(false);
    setSearchTerm(text);
  }

  useEffect(() => {
    // setFilteredList
    searchHandler(searchTerm, list);
  }, [list]);

  return (
    <div className="side-nav-dropdown">
      {loader && (
        <div className="scrap-file-uploader__loading-overlay">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
        </div>
      )}
      {!notSearchable && placeholder !== 'Search Collections' && (
        <div className="side-nav-dropdown__search">
          <input
            value={searchTerm}
            onChange={(e) => searchHandler(e.target.value, list, true)}
            onFocus={() => dispatch(canPaste(false))}
            onBlur={() => {
              dispatch(canPaste(true));
              setSearchTerm(searchTerm.trim())
            }}
            className="side-nav-dropdown__search-input"
            placeholder={placeholder || 'Search Something'}
          />

          <span className="side-nav-dropdown__search-icon">
            <Icon iconClass="search" size="normal" />
          </span>
        </div>
      )}

      {(list.length > 0 ||
        searchTerm.length > 0 ||
        showUncategorized ||
        showCollections ||
        showMyScraps ||
        showSavedScraps ||
        showSavedCollections ||
        shoCollaborations) && (
        <ul
          //@ts-ignore
          ref={ref}
          className={`side-nav-dropdown__list${
            scrollable ? ' side-nav-dropdown__list--scrollable' : ''
          }`}
          onScroll={handleScroll}
        >
          {list.length >= 0 && searchTerm.length > 0 ? (
            <li className="side-nav-dropdown__no-data-message">
              {creatable
                ? createOption()
                : listToShow.length > 0
                  ? ' '
                  : 'No Results found!'}
            </li>
          ) : (
            ''
          )}
          {showMyScraps && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized ${
                pathname === '/'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Scraps')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon my-scraps-icon">
                <MyScrapsIcon />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                My Scraps
              </span>
            </button>
          )}
          {showUncategorized && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized ${
                pathname === '/uncategorized'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Uncategorized')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon">
                <UncategorizedIcon />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                Uncategorized
              </span>
            </button>
          )}
          {showSavedScraps && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized ${
                pathname === '/saved' && search === '?type=scraps'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Saved-Scraps')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon">
                <Nut />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                Saved Scraps
              </span>
            </button>
          )}
          
          {showCollections && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized ${
                pathname === '/collections'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Collections')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon">
                <CollectionIcon />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                All Collections
              </span>
            </button>
          )}
          {showSavedCollections && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized ${
                pathname === '/saved' && search === '?type=collections'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Saved-Collections')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon">
                <BookmarkIcon isFilled />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                Saved Collections
              </span>
            </button>
          )}

          {shoCollaborations && !searchTerm?.trim() && (
            <button
              className={`side-nav-dropdown__list__uncategorized side-nav-dropdown__list__uncategorized--underlined ${
                pathname === '/collaborations'
                  ? 'side-nav-dropdown__list-item--active'
                  : ''
              }`}
              onClick={() => onClick('Collaborations')}
            >
              <span className="side-nav-dropdown__list__uncategorized__icon">
                <Collaborator />
              </span>
              <span className="side-nav-dropdown__list__uncategorized__label">
                Collaborations
              </span>
            </button>
          )}

          {listToShow.length > 0
            ? listToShow.map((item) => (
              <Fragment key={item.id}>{children(item)}</Fragment>
            ))
            : ''}

          {/* {canToggle && !showFull && (
            <li className="side-nav-dropdown__toggler">
              <button
                className="side-nav-dropdown__toggler-button"
                onClick={() => setFullView(!fullView)}
              >
                {fullView
                  ? 'View less'
                  : `View more ... (${filteredList.length - minItemsSize})`}
              </button>
            </li>
          )} */}
        </ul>
      )}
    </div>
  );
}
