import React, { useState } from 'react';
import './styles.css';

import {
  BookmarkIcon,
  Collaborator,
  CollectionOutlined,
  InviteIcon,
  LeaveIcon,
  ShareIcon,
  SlashedEye,
  TrashOutlineIcon,
} from '../../../Global/icons';
import { collectionType } from '../../../../types/collections';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { useHistory } from 'react-router-dom';
import { GroupMemberType } from '../../../../containers/GroupShareContainer/types';
import {
  formatTimestampDate,
  handleShare, returnTimeDifference,
  showFullDate,
} from '../../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';
import MyDropDown from '../../../Global/MyDropDown';
import TripleDot from '../../../Global/icons/TripleDot';
import Icon from '../../../Global/Icon';
import PencilEdit from '../../../Global/icons/PencilEdit';
import Organize from '../../../Global/icons/Organize';
import { returnShareLink } from '../../../../helpers/returnShareLinks';
import Trust from '../../../Trust';
import TrustIcon from '../../../Trust/TrustIcon';

interface dropdownType {
  valueField: footerOptionsType;
  icon?: React.ReactElement;
  textField?: string | React.JSX.Element;
  isIconStroked?: boolean;
  className?: string;
}

export interface collectionCardFooterOptionsType {
  list: Array<footerOptionsType>;
  onOptionClick: (type: footerOptionsType) => void;
}

interface Proptypes {
  fromString?: string;
  collaborators?: collectionType['members'] | GroupMemberType[];
  avatar?: string;
  className?: string;
  maxLine?: number;
  count?: number;
  cover_image?: string;
  user_name?: string;
  display_name?: string;
  type?: 'collection' | 'group';
  groupNickName?: string;
  view?: 'all-shares' | 'group-page-details';
  showOwner?: boolean;
  date?: string;
  isBookmarked?: boolean;
  dropdownOptions?: collectionCardFooterOptionsType;
  visibilityStatus?: number;
  slug?: string;
  private_key?: string;
  userName?: string;
  is_trusted?: boolean;
  trustCb?: (val: boolean) => void;
  userId?: number;
  updatedAt?: string;
  createdAt?: string;
}

type footerOptionsType =
  | 'edit'
  | 'manageCollaborators'
  | 'share'
  | 'organize'
  | 'leaveCollection'
  | 'mute'
  | 'bookmark'
  | 'trust'
  | 'convert'
  | 'editShareGroup'
  | 'delete'
  | 'leave';

const NewCollectionHeader = ({
  fromString,
  avatar,
  collaborators,
  className,
  maxLine,
  count,
  cover_image = '',
  user_name = '',
  display_name = '',
  type,
  groupNickName = '',
  view,
  showOwner: showAuthor,
  date,
  isBookmarked,
  dropdownOptions,
  visibilityStatus,
  slug,
  private_key,
  userName,
  is_trusted,
  trustCb,
  userId,
  updatedAt,
  createdAt,
}: Proptypes) => {
  const user = useSelector((state: ReduxStateType) => state.user);
  const isGroup = type === 'group';
  const { push } = useHistory();
  const [copied, setCopied] = useState<{
    direction: 'left' | 'right';
  }>(null);
  const showOwner =
    showAuthor || (collaborators?.length <= 1 && type !== 'group');

  const shareUrl = returnShareLink(
    'collection',
    slug,
    userName,
    false,
    visibilityStatus,
    private_key
  );

  const handleCopy = (direction: typeof copied['direction']) => {
    // @ts-ignore
    handleShare(shareUrl, 'show', async () => {
      setCopied({ direction });
      setTimeout(() => setCopied(null), 1500);
      await navigator.clipboard.writeText(encodeURI(shareUrl || ''));
    });
  };

  const optionsMapper: {
    bookmark: { icon: React.JSX.Element; className: string; valueField: string; textField: string };
    editShareGroup: { icon: React.JSX.Element; valueField: string; textField: string };
    edit: { icon: React.JSX.Element; valueField: string; textField: string };
    organize: { icon: React.JSX.Element; valueField: string; textField: string };
    leave: { icon: React.JSX.Element; valueField: string; textField: string };
    manageCollaborators: { icon: React.JSX.Element; valueField: string; textField: string };
    share: { icon: React.JSX.Element; valueField: string; textField: string };
    mute: { icon: React.JSX.Element; valueField: string; textField: string };
    convert: { icon: React.JSX.Element; valueField: string; textField: string };
    delete: { icon: React.JSX.Element; valueField: string; isIconStroked: boolean; textField: string };
    leaveCollection: { icon: React.JSX.Element; valueField: string; textField: string }
    trust: { icon: React.JSX.Element; valueField: string; textField: React.JSX.Element }
  } = {
    edit: {
      valueField: 'edit',
      textField: 'Edit',
      icon: <PencilEdit />,
    },
    manageCollaborators: {
      valueField: 'manageCollaborators',
      textField: 'Manage Collaborators',
      icon: <Collaborator />,
    },
    share: {
      valueField: 'share',
      textField: 'Share Collection',
      icon: <ShareIcon />,
    },
    organize: {
      valueField: 'organize',
      textField: 'Organize Collection',
      icon: <Organize />,
    },
    leaveCollection: {
      valueField: 'leaveCollection',
      textField: 'Leave',
      icon: <LeaveIcon />,
    },
    // Group options
    mute: {
      valueField: 'mute',
      textField: 'Mute',
      icon: <SlashedEye />,
    },
    bookmark: {
      valueField: 'bookmark',
      textField: isBookmarked ? 'Saved' : 'Save',
      icon: <BookmarkIcon isFilled={isBookmarked} />,
      className: isBookmarked ? 'dropdown__item--active' : '',
    },
    convert: {
      valueField: 'convert',
      textField: 'Convert',
      icon: <CollectionOutlined />,
    },
    delete: {
      valueField: 'delete',
      textField: 'Delete',
      icon: <TrashOutlineIcon />,
      isIconStroked: true,
    },
    editShareGroup: {
      valueField: 'editShareGroup',
      textField: 'Edit Share Group',
      icon: <InviteIcon />,
    },
    leave: {
      valueField: 'leave',
      textField: 'Leave',
      icon: <LeaveIcon />,
    },
    trust: {
      valueField: 'trust',
      textField: <Trust isTrusted={is_trusted} userId={userId} cb={(state) => trustCb(state)} />,
      icon: <TrustIcon type={is_trusted ? 'fill' : 'outline'} />,
    },
  };

  const dropdownList: dropdownType[] = [];
  const { list, onOptionClick } = dropdownOptions || {};

  if (list?.length) {
    list.forEach((item) => {
      if (item === 'share' && visibilityStatus !== 2 && visibilityStatus !== 3)
        return;
      dropdownList.push(optionsMapper[item] as dropdownType);
    });
  }

  // if there is user_name, add a dropdown item to Trust Scrapper
  if (showOwner && userId !== user.user_id) {
    dropdownList.push({
      valueField: 'trust',
      // @ts-ignore
      textField: <Trust isTrusted={is_trusted} userId={userId} cb={(state) => trustCb(state)} />,
      icon: <TrustIcon type={is_trusted ? 'fill' : 'outline'} />,
    });
  }

  const dateValue    = (dateValue) => {
    if (!dateValue || isGroup) return null;
    return (
      <span
        className="new-collection-header__date"
        title={showFullDate(dateValue, user.timezone)}
      >
        <span className="new-collection-header__date-pointer" />
        {'• '}
        {formatTimestampDate(dateValue, user.timezone)}
      </span>
    );
  };

  const getGroupUser = () => {
    if (collaborators.length !== 1) {
      return null;
    }

    if(user.userName !== collaborators[0].user_name) {
      return {
        user_name: collaborators[0].user_name,
        avatar: collaborators[0].avatar,
      }
    }

    return {
      user_name,
      avatar,
    };
  }

  return (
    <div
      className={`new-collection-header ${className ? className : ''} ${
        !cover_image
          ? 'new-collection-header--show-full'
          : 'new-collection-header--show-half'
      }`}
    >
      <div className="new-collection__user-wrapper">
        <div className="new-collection__user-avatar">
          {!isGroup && !showOwner && !groupNickName && collaborators.length ? (
            <span className="new-collection-header__collaborator__icon">
              <Collaborator />
            </span>
          ) : null}

          {!isGroup && showOwner && (
            <span className="new-collection-header__collaborator__owner">
              <CollectionHeaderAvatar
                fromString={fromString}
                className="new-collection-header__owner__icon"
                userName={user_name ?? user_name}
                image={avatar ?? avatar}
              />
            </span>
          )}

          {isGroup && collaborators.length === 1 && (
            <span className="new-collection-header__collaborator__owner">
              <CollectionHeaderAvatar
                fromString={fromString}
                className="new-collection-header__owner__icon"
                userName={getGroupUser().user_name ?? getGroupUser().user_name}
                image={getGroupUser().avatar ?? getGroupUser().avatar}
              />
            </span>
          )}
        </div>

        <div
          className={`new-collection__user-details new-collection-header__collaborator  new-collection-header__collaborator--owner-${
            !isGroup && showOwner ? 'visible' : 'hidden'
          }`}
        >
          {!showOwner && (groupNickName || collaborators.length > 1) ? (
            <>
              <p className="new-collection-header__collaborator__members">
                <span className="new-collection-header__user-list">
                  {groupNickName && count > 1
                    ? groupNickName
                    : collaborators
                      ?.map((item) => item.display_name ?? item.user_name)
                      .filter((name) => !!name)
                      .join(', ')}
                </span>

                {!isGroup && count > 1 && (
                  <span
                    className={`new-collection-header__collaborator__count`}
                  >
                    {count}
                  </span>
                )}

                {/*{!isGroup && dateValue(date)}*/}
              </p>
              {/* {!groupNickName && count > 0 && view === 'all-shares' && (
              <span className={`new-collection-header__collaborator__count`}>
                {count}
              </span>
            )} */}
            </>
          ) : null}

          {/* FOR COLLECTIONS */}
          {!isGroup && showOwner && (
            <>
              <span className="new-collection-header__owner-details">
                {display_name && (
                  <>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        push(`/${user_name}${fromString ?? ''}`);
                      }}
                      className="new-collection-header__owner-details__display-name"
                    >
                      {display_name}{' '}
                      <Trust
                        userId={userId}
                        isTrusted={is_trusted}
                        view="icon"
                        cb={(state) => trustCb(state)}
                      />
                    </span>
                    {/*{dateValue(date)}*/}
                  </>
                )}
              </span>

              {user_name && (
                <span className="new-collection-header__owner-details__user-name">
                  {'@' + user_name}
                </span>
              )}
            </>
          )}

          {/* FOR GROUPS */}
          <>
            {isGroup && collaborators.length === 1 && (
              <span className="new-collection-header__owner-details">
                {collaborators[0].display_name && (
                  <>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        push(
                          `/${collaborators[0].user_name}${fromString ?? ''}`
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                      className="new-collection-header__owner-details__display-name"
                    >
                      {collaborators[0].display_name}{' '}
                      <Trust
                        isTrusted={is_trusted || collaborators[0].is_trusted}
                        userId={collaborators[0].user_id}
                        view="icon"
                        cb={(state) => trustCb(state)}
                      />
                    </span>
                    {/*{dateValue(date)}*/}
                  </>
                )}
              </span>
            )}

            {isGroup && (
              <div className="new-collection-header__username-date">
                {collaborators[0].user_name && (
                  <span className="new-collection-header__owner-details__user-name">
                    {
                      collaborators.length === 1 && (
                        <>{'@' + collaborators[0].user_name}</>
                      )
                    }
                  </span>
                )}

                <div className="new-collection-card__date-controls">
                  {(updatedAt && view === 'all-shares') ? (
                    <span className="collection-card-wrapper__footer__time">
                      <p
                        className="collection-header__head-last-updated__meta-time collection-header__head-last-updated__meta-time__updated_at"
                        title={showFullDate(updatedAt, user.timezone)}
                      >
                        {returnTimeDifference(createdAt, updatedAt) > 15
                          ? formatTimestampDate(updatedAt, user.timezone)
                          : formatTimestampDate(createdAt, user.timezone)}
                      </p>
                    </span>
                  ) : null}

                  <div style={{ position: 'relative' }}>
                    {dropdownList.length ? (
                      // @ts-ignore TODO: FIX TYPE
                      <MyDropDown<dropdownType>
                        dropDownList={dropdownList}
                        customButton={{
                          customRender: (showDropDownListHandler) => {
                            return (
                              <div className="scrap-card__footer-button new-collection__triple-dot scrap-card__footer-triple-dot-button">
                                <TripleDot
                                  onMouseDown={(e) => e.stopPropagation()}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showDropDownListHandler();
                                  }}
                                />
                              </div>
                            );
                          },
                        }}
                      >
                        {(item, onClicked) => {
                          if (item.valueField === 'organize') {
                            return null;
                          } else
                            return (
                              <div
                                key={item.valueField}
                                className={`dropdown__item${
                                  item.className ? ' ' + item.className : ''
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  item.valueField === 'share' && handleCopy('right');
                                  onOptionClick(item.valueField);
                                  onClicked && onClicked();
                                }}
                              >
                                <span
                                  className={`dropdown__item-icon${
                                    item.isIconStroked
                                      ? ' dropdown__item-icon--stroked'
                                      : ''
                                  }`}
                                >
                                  {item?.icon}
                                </span>

                                <span className="dropdown__item-label">
                                  {item?.textField}
                                </span>
                              </div>
                            );
                        }}
                      </MyDropDown>
                    ) : null}

                    {copied && (
                      <div
                        className={`collection-card-wrapper__footer__copied collection-card-wrapper__footer__copied--${copied.direction}`}
                      >
                        <Icon iconClass="check-square" size="big" />
                        Link copied to clipboard
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      {/* <span>{namesString}</span> */}

      {!isGroup && (
        <div style={{ position: 'relative' }}>
          {dropdownList.length ? (
            // @ts-ignore TODO: FIX TYPE
            <MyDropDown<dropdownType>
              dropDownList={dropdownList}
              customButton={{
                customRender: (showDropDownListHandler) => {
                  return (
                    <div className="scrap-card__footer-button new-collection__triple-dot scrap-card__footer-triple-dot-button">
                      <TripleDot
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          showDropDownListHandler();
                        }}
                      />
                    </div>
                  );
                },
              }}
            >
              {(item, onClicked) => {
                if (item.valueField === 'organize') {
                  return null;
                } else
                  return (
                    <div
                      key={item.valueField}
                      className={`dropdown__item${
                        item.className ? ' ' + item.className : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        item.valueField === 'share' && handleCopy('right');
                        onOptionClick(item.valueField);
                        onClicked && onClicked();
                      }}
                    >
                      <span
                        className={`dropdown__item-icon${
                          item.isIconStroked
                            ? ' dropdown__item-icon--stroked'
                            : ''
                        }`}
                      >
                        {item?.icon}
                      </span>

                      <span className="dropdown__item-label">
                        {item?.textField}
                      </span>
                    </div>
                  );
              }}
            </MyDropDown>
          ) : null}

          {copied && (
            <div
              className={`collection-card-wrapper__footer__copied collection-card-wrapper__footer__copied--${copied.direction}`}
            >
              <Icon iconClass="check-square" size="big" />
              Link copied to clipboard
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default NewCollectionHeader;
