import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInvitationPopup,
  setIsMainPageLoading,
  setReloadSideNavShares,
} from '../redux/action/utils';
import MyContacts, {
  ContactCard,
  ContactsControl,
  SearchContacts,
} from '../components/MyContacts';
import { GlobalSearchUserType } from '../types/globalSearchDataType';
import VERIFY_INVITE_TOKEN from './data/verifyInviteToken';
import { GET_CONTACTS_INVITATIONS } from './data/contactsInvitationList';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { ACCEPT_CANCEL_INVITATION } from './data/acceptCandelInvitations';
import { SEND_WITHDRAW_CONTACT_REQUEST } from './data/sendWidrawInvitations';
import { REMOVE_CONTACT } from './data/removeContact';
import { LOGIN_VIA } from '../helpers/config';
import { ReduxStateType } from '../redux/store';
import { setSupabaseInvitationsCountSetter } from '../redux/action/supabaseActions';
import { getResponseMessages, parseUrlParams, waitBeforeExecute } from '../helpers';
import { RequestStatusObjectType } from '../types/requestStatusType';
import SEOMeta from '../components/Meta/SEOMeta';
import { useHistory, useLocation } from 'react-router-dom';

export interface nonScrappiUsers {
  created_at: string;
  email: string;
  phone: string;
}
export type verifyInviteStatusType = 'verified' | 'invalid' | 'processing'

const MyContactsContainer = () => {
  const { search: urlSearch } = useLocation();
  const {push} = useHistory();
  const { invitationToken: token } = parseUrlParams(urlSearch);
  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [label, setLabel] = useState<'contacts' | 'invitations' | 'trusts' | 'trusted-scrappers'>('contacts');
  const [invitationsCount, setInvitationsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const client = useApolloClient();
  const [sendOrWithdrawInvitations] = useMutation(
    SEND_WITHDRAW_CONTACT_REQUEST
  );
  const [acceptOrCancelInvitations] = useMutation(ACCEPT_CANCEL_INVITATION);
  const [removeContact] = useMutation(REMOVE_CONTACT);
  const [selectedUserName,setSelectedUserName] = useState("");
  let numOfRecords = 15;

  let variables = {
    action: label,
    page: page,
    first: numOfRecords,
    text: search,
  };
  const { message } = useSelector((state: ReduxStateType) => state.supabase);

  const {data: verifyInviteData, loading: verifyInviteLoading} = useQuery(VERIFY_INVITE_TOKEN(), {
    variables: {
      token
    },
    skip: typeof token !== 'string',
  })
  const { data: contactsInfo, loading, refetch, fetchMore } = useQuery(
    GET_CONTACTS_INVITATIONS,
    {
      variables,
      client,
      fetchPolicy: 'cache-and-network',
      skip: verifyInviteLoading
    }
  );

  const cache = client.cache;

  let data: GlobalSearchUserType[] =
    contactsInfo?.getMyContactsOrInvitations?.data?.knot || [];
  let trusted = contactsInfo?.getUsersITrust?.data?.knot || [];
  let trustMe = contactsInfo?.getUsersTrustMe?.data?.knot || [];
  console.log('trusted', trusted);

  let nonScrappiUsers =
    contactsInfo?.getMyContactsOrInvitations?.data?.non_scrappi_users;
  // This function removes single contact item from cached list and updates the list by fetchMore
  const removeContactFromCacheAndFetchMore = async (
    userName: string,
    email = null
  ) => {
    cache.updateQuery(
      { query: GET_CONTACTS_INVITATIONS, overwrite: true, variables },
      (prevCache) => {
        return {
          ...prevCache,
          getMyContactsOrInvitations: {
            ...prevCache.getMyContactsOrInvitations,
            data: {
              ...prevCache.getMyContactsOrInvitations.data,
              knot: prevCache?.getMyContactsOrInvitations.data?.knot?.filter((item) => {
                if (email) {
                  return item.email !== email;
                }
                return item.user_name !== userName;
              }),
              knot_count: {
                ...prevCache.getMyContactsOrInvitations.data.knot_count,
                scrappers: prevCache?.data?.knot_count?.scrappers - 1,
              },
            }
          }
        }
      }
    );

    await waitBeforeExecute(() => {
      fetchInvitationsCountHandler();
      fetchMore({ variables });
    }, 500);
  };
  const fetchInvitationsCountHandler = async () => {
    try {
      const response = await client.query({
        query: GET_CONTACTS_INVITATIONS,
        variables:    {
          action: 'invitations',
          page: 1,
          first: 1,
        },
      });
      const count =
        response.data.getMyContactsOrInvitations.data.knot_count.received_notification;
      setInvitationsCount(count);
      dispatch(
        setSupabaseInvitationsCountSetter({
          value: count,
        })
      );
    } catch (error) {
      setInvitationsCount(0);
      dispatch(
        setSupabaseInvitationsCountSetter({
          value: null,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setIsMainPageLoading(false));
    // if URL has invite query param then open invitation by updating setLabel to "invitations"
    if (typeof document === 'object' && window.location.search.includes('invite')) {
      setLabel('invitations');
    }
  }, []);

  useEffect(() => {
    fetchInvitationsCountHandler();
  }, []);
  useEffect(() => {
    if (message && message.eventType !== 'DELETE') {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getMyContactsOrInvitations',
      });
      refetch({ page: 1 });
      setPage(1);
      fetchInvitationsCountHandler();
    }
  }, [message]);

  function loadMoreHandler() {
    if (data.length % numOfRecords !== 0) return;
    setPage(Math.floor(data.length / numOfRecords) + 1);
  }

  //The below function is used to  accept or reject invitation based on the passed parameters
  const handleInvitation = async (userName, isAccepted, isCancelled) => {
    setStatus({ status: 'processing' });
    try {
      const res = await acceptOrCancelInvitations({
        variables: {
          userName: userName,
          isCancelled: isCancelled,
          isAccepted: isAccepted,
        },
      });
    } catch (error) {
      //
    }
    await removeContactFromCacheAndFetchMore(userName);
    setStatus({ status: 'not-started' });
  };

  //The below function is used to removed contact based on the userName
  const handleRemoveContact = async (userName) => {
    setStatus({ status: 'processing' });
    try {
      const res = await removeContact({
        variables: {
          userName: userName,
        },
      });
      dispatch(setReloadSideNavShares(true))
      await removeContactFromCacheAndFetchMore(userName);
    } catch (error) {
      //
    }
    setStatus({ status: 'not-started' });
  };

  //this function is used for withdrawing invitation based on email
  const handleWithdrawInvitation = async (email: string, phone: string, userName: string) => {
    setStatus({ status: 'processing' });
    let variables = {
      isSending: false,
      isWithdrawing: true,
      login_via: email ? LOGIN_VIA.email : LOGIN_VIA.phone_number,
      name: userName
    };
    if (email) {
      variables['email'] = email;
    } else if (phone) {
      variables['phone'] = phone;
    }
    try {
      await sendOrWithdrawInvitations({
        variables: variables,
      });
        
    } catch (error) {
      // 
    }
    await removeContactFromCacheAndFetchMore(null, email);
    
    setStatus({ status: 'not-started' });
  };

  //Below function return the control headers
  const renderContactsControl = () => {
    return (
      <ContactsControl
        label={label}
        onLabelClick={(label) => {
          setLabel(label);
          setSearch('');
          setPage(1);
        }}
        count={invitationsCount}
        onInviteClick={() => {
          dispatch(
            setInvitationPopup({
              onComplete: async () => {
                if (label === 'contacts') return;
                const data = await client.query({
                  query: GET_CONTACTS_INVITATIONS,
                  variables: { ...variables, page: 1 },
                });
                const usersList: GlobalSearchUserType[] =
                  data.data?.getMyContactsOrInvitations?.data?.knot;
                const nonScrapiUsersList: nonScrappiUsers[] =
                  data.data?.getMyContactsOrInvitations?.data
                    ?.non_scrappi_users;
                const usersCount: number =
                  data.data?.getMyContactsOrInvitations?.data?.knot_count
                    ?.scrappers;

                if (
                  (Array.isArray(usersList) ||
                    Array.isArray(nonScrapiUsersList)) &&
                  (usersList?.length > 0 || nonScrapiUsersList?.length > 0)
                ) {
                  cache.updateQuery(
                    {
                      query: GET_CONTACTS_INVITATIONS,
                      variables,
                      overwrite: true,
                    },
                    (data) => {
                      // To deep clone data since it has some read only properties nested
                      const newData = JSON.parse(JSON.stringify(data));
                      const knotData =
                        newData?.getMyContactsOrInvitations?.data;
                      //for non scrappi users
                      if (
                        !knotData.non_scrappi_users &&
                        Array.isArray(nonScrapiUsersList)
                      ) {
                        knotData.non_scrappi_users = nonScrapiUsersList;
                      }
                      if (Array.isArray(knotData.non_scrappi_users)) {
                        if (nonScrapiUsersList[0]?.email) {
                          if (
                            nonScrapiUsersList[0]?.email !==
                              knotData?.non_scrappi_users[0]?.email ||
                            !knotData?.non_scrappi_users
                          ) {
                            knotData.non_scrappi_users.unshift(
                              nonScrapiUsersList[0]
                            );
                          }
                        }
                        if (nonScrapiUsersList[0]?.phone) {
                          if (
                            nonScrapiUsersList[0]?.phone !==
                              knotData?.non_scrappi_users[0]?.phone ||
                            !knotData?.non_scrappi_users
                          ) {
                            knotData?.non_scrappi_users.unshift(
                              nonScrapiUsersList[0]
                            );
                          }
                        }
                      }
                      //for scrappi users

                      if (
                        Array.isArray(knotData.knot) &&
                        usersList[0]?.user_name !== knotData?.knot[0]?.user_name
                      ) {
                        knotData?.knot.unshift(usersList[0]);
                        if (knotData.knot.length > page * numOfRecords)
                          knotData?.knot.pop();
                        if (knotData.knot_count) {
                          knotData.knot_count.scrappers = usersCount;
                        }
                      }
                      return newData;
                    }
                  );
                }
                await fetchInvitationsCountHandler();
              },
            })
          );
        }}
      />
    );
  };

  //below function is used to render
  const renderSearch = () => {
    return (
      <SearchContacts
        handleSearch={setSearch}
      />
    );
  };

  // set the correct user data based on the label
  let targetData = data;
  if(label === 'trusts') {
    targetData = trusted;
  }
  if(label === 'trusted-scrappers') {
    targetData = trustMe;
  }

  const renderNonScrappiUser = () => {
    if (!nonScrappiUsers?.length) return null;
    return (
      <div className="my-contacts__contacts-wrapper">
        {nonScrappiUsers?.map((data) => {
          return (
            <ContactCard
              type='non-scrappi-user'
              key={data.email}
              nonScrappiUsers={data}
              handleInvitation={(userName, isAccepted, isCancelled) =>
                handleInvitation(userName, isAccepted, isCancelled)
              }
              label={label}
              data={data}
              loading={loading}
              handleWithdrawInvitation={(email, phone, userName) =>
                handleWithdrawInvitation(email, phone, userName)
              }
              handleRemoveContact={(userName) => handleRemoveContact(userName)}
            />
          );
        })}
      </div>
    );
  };

  // Variable which helps in determining the status of invite token verification query
  // Used to check if the the the invite link which he recieved is valid or not,
  // If not valid, then the link maybe got expired or it is for another user
  const verifyInviteStatus: verifyInviteStatusType = useMemo(() => {
    if (token) {
      if (verifyInviteLoading) return 'processing';
      else if (verifyInviteData?.verifyInviteToken) {
        const { isSuccess } = getResponseMessages(
          verifyInviteData.verifyInviteToken
        );
        return isSuccess ? 'verified' : 'invalid';
      } else return 'invalid';
    } else return null;
  }, [verifyInviteData, verifyInviteLoading, token]);

  // Useeffect for handling the process of invite token verification query
  // if the response contains error, then redirect user to /not-found
  useEffect(() => {
    if (verifyInviteStatus === 'invalid') push('/not-found');
    else if (verifyInviteStatus) {
      setLabel('invitations');
    }
  }, [verifyInviteStatus]);

  return (
    <>
      <SEOMeta title='Scrappi | Contacts' />
      <MyContacts
        isMutationProcessing={status.status === 'processing'}
        data={targetData}
        onLoadMore={loadMoreHandler}
        renderContactsControl={renderContactsControl}
        renderSearch={renderSearch}
        loading={loading}
        label={label}
        renderNonScrappiUser={() => renderNonScrappiUser()}
      >
        {(user) => {
          return (
            <ContactCard
              type={
                label === 'contacts'
                  ? 'my-contact'
                  : user.is_sent
                    ? 'recieved-request'
                    : 'sent-request'
              }
              handleInvitation={(userName, isAccepted, isCancelled) =>
                handleInvitation(userName, isAccepted, isCancelled)
              }
              label={label}
              data={user}
              loading={loading}
              handleWithdrawInvitation={(email, phone, userName) =>
                handleWithdrawInvitation(email, phone, userName)
              }
              handleRemoveContact={(userName) => handleRemoveContact(userName)}
              selectedUserName={selectedUserName} 
              setSelectedUserName={(userName)=>setSelectedUserName(userName)}
            />
          );
        }}
      </MyContacts>
    </>
  );
};

export default MyContactsContainer;
