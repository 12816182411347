import React, { useEffect } from 'react';
import './MarketingStyles.css';
import MarketingPrice from "./MarketingPrice";
import { isMobileDevice } from '../../helpers';
import * as config from '../../settings.json';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';

const Marketing = ({ isCollectionPage }: { isCollectionPage?: boolean }) => {
  // get user from the global redux store
  const user = useSelector((state: ReduxStateType) => state.user);

  useEffect(() => {
    // if it is not server set a new localstorage value on key "theme" as "dark"
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', 'dark');
    }
  }, []);

  if(user?.user_id) {
    return null;
  }

  let webView = null;
  if (typeof window !== 'undefined') {
    // the app webview adds a variable to the window object
    // if it exists, then it is the mobile app
    // @ts-ignore
    webView = !!window.ReactNativeWebView;
  }

  function getMobileOperatingSystem() {
    if (typeof window !== 'undefined') {
      if (webView) {
        return 'app';
      }

      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.Stream) {
        return 'iOS';
      }
    }

    return 'unknown';
  }

  let storeLink = config?.chromeExtensionStoreUrl;
  if(isMobileDevice() && getMobileOperatingSystem() === 'Android') {
    storeLink = config?.playStore;
  }
  if (isMobileDevice() && getMobileOperatingSystem() === 'iOS') {
    storeLink = config?.appStore;
  }

  return (
    <div
      className={`marketing ${
        isCollectionPage ? 'marketing__collection-page' : ''
      }`}
    >
      {/* Buttons default layout */}
      {!isCollectionPage && (
        <div className="s-hero_button">
          <a
            href="https://app.scrappi.com/register"
            target="_blank"
            className="s-orange-button w-inline-block"
          >
            <div className="s-text-16px">Get Started with Scrappi</div>
          </a>
          <a
            href={storeLink}
            target="_blank"
            className="s-orange-button white spec w-inline-block"
          >
            <div className="s-orange-button-img">
              <img
                src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb6039_chrome.svg"
                loading="eager"
                alt="Scrappi chrome"
                className="object-contain"
              />
            </div>
            <div className="s-orange-button-img">
              <img
                src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb5fba_apple%20logo.svg"
                loading="eager"
                alt="Scrappi apple"
                className="object-contain"
              />
            </div>
            <div className="s-orange-button-img">
              <img
                src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb5fb6_android%20logo.svg"
                loading="eager"
                alt="Scrappi  android"
                className="object-contain"
              />
            </div>

            <div className="s-text-16px">
              Download Now
            </div>
          </a>
        </div>
      )}

      {/* Buttons for collection grid */}
      {isCollectionPage && (
        <div className="s-hero_button marketing__collection-buttons">
          <div className="marketing__cta-wrapper">
            <a
              href="https://app.scrappi.com/register"
              target="_blank"
              className="s-orange-button w-inline-block"
            >
              <div className="s-text-16px">Get Started with Scrappi</div>
            </a>
            <a
              href={storeLink}
              target="_blank"
              className="s-orange-button white spec w-inline-block"
            >
              <div className="s-orange-button-img">
                <img
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb6039_chrome.svg"
                  loading="eager"
                  alt="Scrappi chrome"
                  className="object-contain"
                />
              </div>
              <div className="s-orange-button-img">
                <img
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb5fba_apple%20logo.svg"
                  loading="eager"
                  alt="Scrappi apple"
                  className="object-contain"
                />
              </div>
              <div className="s-orange-button-img">
                <img
                  src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb5fb6_android%20logo.svg"
                  loading="eager"
                  alt="Scrappi  android"
                  className="object-contain"
                />
              </div>
              <div className="s-text-16px">Download Now</div>
            </a>
          </div>

          <a
            href="https://app.scrappi.com/register"
            target="_blank"
            className="marketing__between-buttons"
          >
            New to Scrappi? Sign up for an Unlimited Free Plan
          </a>
        </div>
      )}

      {/* right left images */}
      <div className="s-hero-img-left">
        <img
          src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb602c_legt%20tree.svg"
          loading="eager"
          alt=""
          className="object-contain"
        />
      </div>

      <div className="s-hero-img-right">
        <img
          src="https://cdn.prod.website-files.com/65fa0fbea0da60da80bb5fb0/65fa0fbea0da60da80bb6026_right%20tree.svg"
          loading="eager"
          alt=""
          className="object-contain"
        />
      </div>

      {/* heading and marketing content only visible with Scrap */}
      {!isCollectionPage && (
        <>
          <h1 className="s-h1">
            Scrap<sup className="sm-head">SM</sup> it and Never forget it
          </h1>

          <p className="s-text-24px resize marketing__text">
            Unleash the Power of Digital Organization
            <br />
            and Unlock Your Creative Potential
            <br />
          </p>

          <MarketingPrice />
        </>
      )}
    </div>
  );
};

export default Marketing;
