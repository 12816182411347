import {gql} from '@apollo/client';

/**
 * Save user social information to the database
 */
export const SAVE_USER_SOCIAL_INFO = gql`
  mutation upsertSocialSync(
    $service_id: Int!
    $service_response: String
    $service_user_id: String
    $service_username: String
  ) {
    upsertSocialSync(
      service_id: $service_id
      service_response: $service_response
      service_user_id: $service_user_id
      service_username: $service_username
    ) {
      data {
        socialSync {
          id
        }
      }
      status
    }
  }
`;

/**
 * Upload CSV file for LinkedIn contacts
 */
export const UPLOAD_LINKEDIN_CONTACTS = gql`
  mutation upsertLinkedInConnections(
    $file: Upload!
  ) {
    upsertLinkedInConnections(
      file: $file
    ) {
      data {
        socialTrusts {
          id
          service_id
        }
      }
      messages {
        error
        success
      }
    }
  }`;


/**
 * Get current user's social sync data
 */
export const GET_USER_SOCIAL_SYNC = gql`
  query GetUserSocialSync {
    socialSyncs(first: 10, page: 1) {
      data {
        socialSyncs {
          service_id
          service_username
          service_user_id
        }
      } 
    }
  }`;

/**
 * Get the list of contacts
 */
export const GET_CONTACTS = gql`
   query socialTrusts($first: Int, $page: Int, $service_id: Int) {
     socialTrusts(first: $first, page: $page, service_id: $service_id) {
       paginatorInfo {
        lastPage
        hasMorePages
        count
        total
      }
       data {
         socialTrusts {
           user_id
           service_id
           service_username
           service_user_id
           social_response
           phone_numbers
           email_addresses
           profile {
             userName
             user_id
             avatar
             display_name
             is_trusted
           }
         }
       }
     }
   }
 `;
/*
 * Unlink the account
 * pass in the service_id that you want to unlink
 * This will remove the user from the trusted users list for that service
 */
export const UNLINK_ACCOUNT = gql`
  mutation deleteSocialSync($service_id: Int!) {
    deleteSocialSync(service_id: $service_id) {
      messages {
        success
        error
      }
    }
  }
`;
/**
 * Auto trust mutation
 */
export const AUTO_TRUST = gql`
  mutation upsertSocialUserTrusts($service_id: Int!) {
    upsertSocialUserTrusts(service_id: $service_id) {
      messages {
        success
        error
      }
    }
  }
`;
/**
 * Mutation to save the list of follows
 */
export const UPSERT_CONTACTS = gql`
  mutation upsertSocialTrusts($social_trusts: [SocialTrustInput]) {
    upsertSocialTrusts(social_trusts: $social_trusts) {
      data {
        socialTrusts {
          id
        }
      }
      messages {
        error
        success
      }
    }
  }
`;
