import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleContactType } from './GoogleContactsType';
import {useMutation} from "@apollo/client";
import {AUTO_TRUST, UPSERT_CONTACTS} from "../ContactImportQueries";
import NewPopup from "../../../../Global/NewPopup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const faSpinnerIcon = faSpinner as IconProp;

const GoogleContactAuth = () => {
  const [gettingContacts, setGettingContacts] = React.useState(false);
  const [upsertContacts] = useMutation(UPSERT_CONTACTS);
  const [upsertSocialUserTrusts] = useMutation(AUTO_TRUST);
  let listOfContacts = [];

  const login = useGoogleLogin({
    // @ts-ignore
    onSuccess: async tokenResponse => {
      setGettingContacts(true);
      listOfContacts = [];
      const contactsList = await autherizeGoogle(tokenResponse);
      // if success, then run auto trust
      if(contactsList) {
        await upsertSocialUserTrusts({
          variables: {
            service_id: 4,
          },
        });
      }
      setGettingContacts(false);

      // after everything is done, reload the page
      reloadPage();
    },
    scope: 'https://www.googleapis.com/auth/contacts.readonly',
  });

  /**
   * Reload the page
   */
  function reloadPage() {
    // if not server refresh the page
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?google-contacts=true';
    }
  }

  /**
   * Fetch Google contacts from the API
   */
  function fetchGoogleContacts(token: string, nextPageToken = null) {
    let url = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,phoneNumbers,photos';
    if (nextPageToken) {
      url += `&pageToken=${nextPageToken}`;
    }

    return fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Format the Google contacts into system-compatible data
   */
  function formatGoogleContacts(contacts: GoogleContactType[]) {
    const social_trusts = [];
    // Step 2: Iterate through each contact and extract email addresses
    contacts.forEach(contact => {
      if (contact?.emailAddresses?.length) {
        contact.emailAddresses.forEach(email => {
          // Check if the email is valid and not already added to the `social_trusts` array
          if (
            email.value &&
            !social_trusts.some(
              variable => variable.service_user_id === email.value.toLowerCase(),
            )
          ) {
            // Add the email to the social trusts array
            social_trusts.push({
              service_id: 4, // Service ID for Google Contacts
              service_username: '_', // Placeholder user ID
              service_user_id: email.value.toLowerCase(), // Lowercased email for consistency
              social_response: JSON.stringify(contact), // Full contact data as JSON string
            });
          }
        });
      }

      if(contact.phoneNumbers?.length) {
        contact.phoneNumbers.forEach(phone => {
          // Check if the phone number is valid and not already added to the `social_trusts` array
          if(
            phone.value &&
            !social_trusts.some(
              variable => variable.service_user_id === phone.value.toLowerCase(),
            )
          ) {
            // Add the phone number to the social trusts array
            social_trusts.push({
              service_id: 4, // Service ID for Google Contacts
              service_username: '_', // Placeholder user ID
              service_user_id: phone.value.toLowerCase(), // Lowercased phone number for consistency
              social_response: JSON.stringify(contact), // Full contact data as JSON string
            });
          }
        });
      }
    });

    return social_trusts;
  }

  /**
   * After the authentication is successful, request the Google Contacts data
   * and save it to the database.
   */
  async function autherizeGoogle(response: { access_token: string}, nextPageToken = null ) {
    // if login has access token, request the Google Contacts data
    if (response?.access_token) {
      const contacts = await fetchGoogleContacts(response.access_token, nextPageToken);
      const data: { connections: GoogleContactType[], nextPageToken: string } = await contacts.json();
      if(!data.connections) return null;

      const importedContacts = [...data.connections];
      // add to existing contacts setGoogleContacts(prev => [...prev, ...data.connections]);
      listOfContacts.push(...importedContacts);
      const formattedContacts = formatGoogleContacts(importedContacts);
      await saveGoogleContacts(formattedContacts);

      const moreData = data?.nextPageToken;
      if (moreData) {
        await autherizeGoogle(response, moreData);
      }
    }

    return listOfContacts;
  }

  /**
   * Save the Google contacts to the database for this service
   */
  async function saveGoogleContacts(formattedContactList) {
    const savedContacts = await upsertContacts({
      variables: {
        social_trusts: formattedContactList,
      },
    });
  }

  if(gettingContacts) {
    return (
      <NewPopup defaultOpen size="Small">
        <div className="x-follows__importing">
          <h2>Importing your Google Contacts</h2>

          <div className="x-follows__importing-spinner">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
            <span>
              {' '}
              Importing follows...
            </span>
          </div>

          <p>
            Please wait while your Google contacts are imported. This may take a few
            moments depending on the number of contacts you have on Google. Please
            do not navigate away from this screen until the import is
            complete.
          </p>
        </div>
      </NewPopup>
    )
  }

  return (
    <span className="google-contact-auth">
      <button
        className="button button__primary"
        onClick={() => login()}
      >
        Connect Google
      </button>
    </span>
  );
}

export default GoogleContactAuth;
