import React, { useEffect, useRef, useState } from 'react';
import * as config from '../../../../settings.json';
import NewPopup from '../../../Global/NewPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XFollowsCard from './components/XFollowsCard';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AUTO_TRUST,
  GET_CONTACTS,
  GET_USER_SOCIAL_SYNC,
  SAVE_USER_SOCIAL_INFO,
  UNLINK_ACCOUNT,
  UPLOAD_LINKEDIN_CONTACTS,
} from './ContactImportQueries';
import { EDIT_PROFILE } from "../../../../containers/data/editProfile";
import { setWarningPopup } from "../../../../redux/action/utils";
import { useDispatch } from "react-redux";

interface userDataType {
  given_name: string;
  family_name: string;
  service_response?: string; // response from the LinkedIn api saved a stringified json
  service_user_id?: string; // url of the profile
  service_username?: string; // username of the profile
}

const LinkedInContacts = ({ socialMediaData } : { socialMediaData: string}) => {
  const isServer = typeof window === 'undefined';
  const [userData, setUserData] = useState<userDataType>(null);
  const [readyForImport, setReadyForImport] = useState(true);
  const [error, setError] = useState<string>(null);
  const [connectionList, setConnectionList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [totalContacts, setTotalContacts] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  const dispatch = useDispatch();

  // mutations to save the data
  const [upsertSocialSync] = useMutation(SAVE_USER_SOCIAL_INFO);
  const [upsertLinkedInConnections] = useMutation(UPLOAD_LINKEDIN_CONTACTS);
  const [unlinkAccount] = useMutation(UNLINK_ACCOUNT);
  const [upsertSocialUserTrusts] = useMutation(AUTO_TRUST);
  // save user's social media profile data
  const [upsertSocialMediaData] = useMutation(EDIT_PROFILE);

  // queries to read data
  const [getUserData] = useLazyQuery(GET_USER_SOCIAL_SYNC);
  const [getLinkedInConnections] = useLazyQuery(GET_CONTACTS);

  // loading ref to track if the contacts are loading or not
  const isLoadingRef = useRef(true);

  // create the input ref for profile link
  const profileLinkRef = useRef(null);
  const fileRef = useRef(null);

  // check if the url params have code and state values
  useEffect(() => {
    if (isServer) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      // handle the code and state values
      getUserProfile(code).then(async (profileData) => {
        // redirect the user to the LinkedIn dashboard page
        console.log('LinkedIn profile fetched successfully', profileData);
        setUserData(profileData);
        await saveCurrentUser({
          url: '',
          userName: '',
        });
      });
    } else {
      getUserData().then((userData) => {
        const existingSyncs = userData.data?.socialSyncs?.data?.socialSyncs;
        if (existingSyncs) {
          // LinkedIn service is service_id 2, filter it out
          const linkedInSyncs = existingSyncs.find(
            (sync) => sync.service_id === 2
          );
          setUserData(linkedInSyncs);
        }
      })

      getLinkedInConnections({
        variables: {
          first: 100,
          page: 1,
          service_id: 2,
        },
      }).then((userData) => {
        const userList = userData.data?.socialTrusts?.data?.socialTrusts;
        const pagingInfo = userData.data?.socialTrusts?.paginatorInfo;
        if (userList) {
          const connections = userList.map((item) => {
            try {
              const contact = JSON.parse(item.social_response);
              return {
                ...item,
                ...contact,
                link: item.service_user_id,
                firstName: contact['First Name'] || contact.first_name,
                lastName: contact['Last Name'] || contact.last_name,
                avatar: contact.avatar,
              };
            } catch (error) {
              return {};
            }
          });

          setHasMorePages(pagingInfo?.hasMorePages || false);
          setTotalContacts(pagingInfo?.total || 0);
          setConnectionList(connections);
        }
      }).finally(() => {
        isLoadingRef.current = false;
      });

      // add a listener to see if the user has scrolled to the bottom of the page
      // add the event listener to the 'profile__wrapper' element as that is the one scrolling
      const profileWrapper = document.querySelector('.profile__wrapper');
      const scrollHandler = (e: Event) => {
        const { scrollHeight, scrollTop, clientHeight } = profileWrapper;

        // if the scroll is more than 300px from the bottom of the page
        if(scrollHeight - scrollTop - 100 <= clientHeight && hasMorePages && !isLoadingRef.current) {
          isLoadingRef.current = true;
          setCurrentPage((prevState) => prevState + 1);
        }
      }
      profileWrapper.addEventListener('scroll', scrollHandler);
      return () => {
        profileWrapper.removeEventListener('scroll', scrollHandler);
      }
    }
  }, []);


  /**
   * UseEffect to check if the user has scrolled to the bottom of the page
   * and get the next page of contacts if they have
   * and append them to the contactsList state
   */
  useEffect(() => {
    if (isServer || currentPage === 1) return;

    if (!hasMorePages) return;
    console.log('currentPage', currentPage);

    getLinkedInConnections({
      variables: {
        first: 100,
        page: currentPage,
        service_id: 2,
      },
    })
      .then((res) => {
        const userList = res.data?.socialTrusts?.data?.socialTrusts || [];
        const pagingInfo = res.data?.socialTrusts?.paginatorInfo;

        const connections = userList.map((item) => {
          try {
            const contact = JSON.parse(item.social_response);
            return {
              ...item,
              ...contact,
              link: item.service_user_id,
              firstName: contact['First Name'] || contact.first_name,
              lastName: contact['Last Name'] || contact.last_name,
              avatar: contact.avatar,
            };
          } catch (error) {
            return {};
          }
        });

        setHasMorePages(pagingInfo?.hasMorePages || false);
        setTotalContacts(pagingInfo?.total || 0);
        setConnectionList((prev) => [...prev, ...connections]);
      })
      .catch((err) => {})
      .finally(() => {
        isLoadingRef.current = false;
      });
  }, [currentPage]);

  /**
   * Handle the profile link click event
   */
  const handleProfileLinkClick = () => {
    // get the profile link from the input ref
    const profileLink = profileLinkRef.current.value;

    setReadyForImport(true);
  };

  /**
   * Make a request to get the user's LinkedIn profile information
   */
  async function getUserProfile(code: string) {
    const response = await fetch(`${config.appUrl}/api/linkedin?code=${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  }

  /**
   * Make a post request to get the user's LinkedIn profile information
   * send firstName, lastName and profile link as url
   */
  async function getUserProfileFromUrl(url: string) {
    const response = await fetch(`${config.appUrl}/api/linkedin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: url,
        // @ts-ignore
        firstName: userData?.given_name,
        // @ts-ignore
        lastName: userData?.family_name,
      }),
    });

    return response.json();
  }

  /**
   * parse user's social media data, add or update X information
   * @param data - existing social media data
   * @param username - X username that we got from authentication
   * @returns string
   */
  function parseSocialMediaData(data: string, username: string) {
    try {
      const parsedData = JSON.parse(JSON.parse(data));
      parsedData['LinkedIn'] = username;
      return JSON.stringify(parsedData);
    } catch (error) {
      console.error('error parsing social media data', error);
      return data;
    }
  }

  /**
   * Save user's data
   * @param url - use as the user's service_user_id
   * @param userName - use as the user's service_username
   */
  async function saveCurrentUser({ url, userName }) {
    // if the url has '/' at the end, remove it
    if(url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    const updatedUserData = {
      ...userData,
      service_user_id: url,
      service_username: userName,
    };

    // save the user's social media data
    const updatedSocialData = parseSocialMediaData(socialMediaData, userName);
    await upsertSocialMediaData({
      variables: {
        social_media_accounts: updatedSocialData,
      },
    });

    // for the first save, when the url and the username are not verified
    // do not update the userData sate, otherwise it overwrites the first and last name
    if(url !== '' && userName !== '') {
      setUserData(updatedUserData);
    }

    return upsertSocialSync({
      variables: {
        service_id: 2,
        service_response: JSON.stringify(userData),
        service_user_id: url,
        service_username: userName,
      },
    });
  }

  /**
   * Redirect the user to the LinkedIn login page
   */
  function redirectToLinkedIn() {
    const clientId = config.lnClientId;
    const redirectUri = `${config.appUrl}/profile`;
    // Define the scopes you want to request
    const scopes = 'profile openid email';

    // Construct the LinkedIn authorization URL
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&state=ln-connect`;

    // Redirect the user to LinkedIn for authorization
    window.location.href = authUrl;
  }

  /**
   * Unlink the LinkedIn account
   * and remove the data
   */
  async function unlinkLinkedIn() {
    setDeleting(true);
    const unlinked = await unlinkAccount({
      variables: {
        service_id: 2,
      },
    });

    console.log(unlinked)

    setDeleting(false);
    setConnectionList([]);
    reloadPage();
  }

  /**
   * Upload CSV file for LinkedIn contacts
   */
  const uploadLinkedInContacts = async () => {
    const file = fileRef.current.files[0];
    const formData = new FormData();
    formData.append('file', file);

    setUploadError(null);
    setUploading(true);
    let uploadFile = null;
    try {
      uploadFile = await upsertLinkedInConnections({
        variables: {
          file: file,
        },
      });

      const hasError = uploadFile.data.upsertLinkedInConnections.messages.error;
      if(hasError) {
        setUploadError(hasError[0]);
      } else {
        // auto trust the users
        await upsertSocialUserTrusts({
          variables: {
            service_id: 2,
          },
        });

        reloadPage();
      }
    } catch (error) {
      setUploadError('Please select a file.');
    }

    setUploading(false);
  };

  function reloadPage() {
    // if not server refresh the page
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?ln-contacts=true';
    }
  }

  return (
    <div className="ln-follows">
      <div className="x-follows__header">
        <h1 className="x-follows__title">LinkedIn Connections</h1>
      </div>

      <div>
        <p className="x-follows__instructions">
          Connect your LinkedIn Account and create Trusted Sources with your
          Connections automatically. New users will be automatically Trusted as
          they join Scrappi and existing users will be Trusted now. Refresh this
          section every so often to capture new LinkedIn Connections.
        </p>

        <hr />
      </div>

      <div>
        <h3>
          <strong>Step 1:</strong> Connect your LinkedIn Account
        </h3>
        <p className="x-follows__instructions">
          Connect your LinkedIn Account to verify your profile.
        </p>

        <div className="x-follows__action-wrapper">
          <p>
            <button
              className="button button__primary button__block"
              onClick={redirectToLinkedIn}
              disabled={!!userData}
            >
              {userData ? 'LinkedIn Connected' : 'Connect LinkedIn'}
            </button>
          </p>

          {userData && (
            <p>
              <button
                className="button button__secondary button__block"
                onClick={() => {
                  dispatch(
                    setWarningPopup({
                      warningMessage:
                        'Are you sure? Click OK to unlink this LinkedIn account.',
                      header: 'Warning',
                      leftLoader:true,
                      cancel: {
                        label: 'Cancel',
                      },
                      submit: {
                        label: 'OK',
                        cb: async () => {
                          await unlinkLinkedIn();
                        },
                      },
                    })
                  )
                }}
                disabled={deleting}
              >
                {deleting ? 'Unlinking account' : 'Unlink LinkedIn'}
              </button>
            </p>
          )}
        </div>
      </div>

      <div
        className={`ln-follows__input-url${
          !userData ? ' ln-follows__section-disabled' : ''
        }`}
      >
        <h3>
          <strong>Step 2:</strong> Enter your LinkedIn Public Profile URL
        </h3>
        <p className="x-follows__instructions">
          Enter your{' '}
          <a href="https://linkedin.com" target="_blank">
            LinkedIn
          </a>{' '}
          Profile URL to verify your profile. When logged into LinkedIn,
          navigate to "Me" and "View Profile" in the navigation. Copy the Public
          Profile in the right sidebar or in the browser URL address bar.
          <br />
          <div className="form-group">
            <div className="form-group__input">
              <div className="label-text-input">
                <input
                  type="text"
                  ref={profileLinkRef}
                  placeholder=""
                  defaultValue={
                    userData?.service_user_id && userData.service_user_id !== '' ? userData.service_user_id : ''
                  }
                  disabled={!!userData?.service_user_id && userData.service_user_id !== ''}
                  className={`label-text-input__input label-text-input__icon profile-input ${
                    userData?.service_user_id
                      ? 'label-text-input__input-disabled'
                      : ''
                  }`}
                />

                <span className="label-text-input__label label-text-input__label-show">
                  LinkedIn Profile URL
                </span>
              </div>

              <button
                className="button button__primary button__block"
                disabled={!!userData?.service_user_id || verifying}
                onClick={async () => {
                  setVerifying(true);
                  // make the API call to verify the value in the input field
                  await getUserProfileFromUrl(
                    profileLinkRef.current.value
                  ).then(async (data) => {
                    if (data.verified) {
                      await saveCurrentUser(data);
                      // trigger auto trust
                      await upsertSocialUserTrusts({
                        variables: {
                          service_id: 2,
                        },
                      });
                      setError(null);
                    } else {
                      console.log('error', data);

                      setError(
                        data.error ||
                          'There was an error verifying the LinkedIn profile. Make sure your profile URL is valid and please try again.'
                      );
                    }
                  });

                  setVerifying(false);
                }}
              >
                {userData?.service_user_id ? 'Verified' : 'Verify'}
              </button>
              {error && <p className="x-follows__error">{error}</p>}
            </div>
          </div>
        </p>
      </div>

      <div
        className={`ln-follows__file-uploader${
          (!userData?.service_user_id && userData?.service_user_id !== '') || 
          !readyForImport ? ' ln-follows__section-disabled' : ''
        }`}
      >
        <h3>
          <strong>Step 3:</strong> Request your LinkedIn Connections
        </h3>
        <p className="x-follows__instructions">
          <a
            href="https://www.linkedin.com/mypreferences/d/download-my-data"
            target="_blank"
            rel="noreferrer"
          >
            Click here
          </a>{' '}
          to go to LinkedIn's "Export your data" screen. Alternatively, you can
          navigate on LinkedIn to: Me &gt; Settings & Privacy &gt; Data Privacy.
          Under the "How LinkedIn uses your data" section, select "Get a copy of
          your data".
          <br />
          Once on the "Export your data" screen, select "Connections" and click
          "Request Archive".
        </p>
      </div>

      <div
        className={`ln-follows__file-uploader${
          (!userData?.service_user_id && userData?.service_user_id !== '') ||
          !readyForImport ? ' ln-follows__section-disabled' : ''
        }`}
      >
        <h3>
          <strong>Step 4:</strong> Upload your LinkedIn Connections
        </h3>
        <p className="x-follows__instructions">
          Once received, extract the zip file and upload the CSV file containing your Connections.
        </p>

        <input type="file" id="file-upload" name="file-upload" ref={fileRef} />

        <button
          className="button button__primary button__block"
          disabled={uploading}
          onClick={uploadLinkedInContacts}
        >
          {uploading ? 'Uploading...' : 'Upload CSV'}
        </button>

        {
          uploadError && <p className="x-follows__error">{uploadError}</p>
        }
      </div>

      <div className={deleting ? 'x-follows__deleting' : ''}>
        {connectionList.length > 0 && (
          <>
            <hr />
            <h3>Your LinkedIn Connections {totalContacts && `(${totalContacts})`}</h3>
          </>
        )}

        {connectionList?.length > 0 &&
          connectionList?.map((user, index) => {
            const {
              firstName,
              lastName,
              service_username,
              description,
              link,
            } = user;
            const profileData = user.profile;

            return (
              <div
                className="x-follows__wrapper small"
                key={`${index}-${service_username}`}
              >
                <XFollowsCard
                  screenName={service_username}
                  name={`${firstName || ''} ${lastName || ''}`}
                  description={description}
                  profileData={profileData}
                  link={link}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LinkedInContacts;
