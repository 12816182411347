import React from 'react';

interface DiscoverIconProps {
  className?: string;
}

const DiscoverIcon: React.FC<DiscoverIconProps> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66667 15.3333L4.09467 14.9907C4.01817 15.1182 3.98648 15.2676 4.00464 15.4151C4.02281 15.5627 4.08978 15.7 4.19491 15.8051C4.30005 15.9102 4.43731 15.9772 4.58487 15.9954C4.73244 16.0135 4.88184 15.9818 5.00933 15.9053L4.66667 15.3333ZM8.66667 8.66667L8.324 8.09467L8.18133 8.18133L8.09467 8.324L8.66667 8.66667ZM15.3333 4.66667L15.9053 5.00933C15.9818 4.88184 16.0135 4.73244 15.9954 4.58487C15.9772 4.43731 15.9102 4.30005 15.8051 4.19491C15.7 4.08978 15.5627 4.02281 15.4151 4.00464C15.2676 3.98648 15.1182 4.01817 14.9907 4.09467L15.3333 4.66667ZM11.3333 11.3333L11.676 11.9053L11.8187 11.8187L11.9053 11.676L11.3333 11.3333ZM18.6667 10C18.6667 11.1381 18.4425 12.2651 18.007 13.3166C17.5714 14.3681 16.933 15.3235 16.1283 16.1283C15.3235 16.933 14.3681 17.5714 13.3166 18.007C12.2651 18.4425 11.1381 18.6667 10 18.6667V20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10H18.6667ZM10 18.6667C7.70146 18.6667 5.49706 17.7536 3.87174 16.1283C2.24643 14.5029 1.33333 12.2985 1.33333 10H0C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20V18.6667ZM1.33333 10C1.33333 7.70146 2.24643 5.49706 3.87174 3.87174C5.49706 2.24643 7.70146 1.33333 10 1.33333V0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H1.33333ZM10 1.33333C11.1381 1.33333 12.2651 1.5575 13.3166 1.99304C14.3681 2.42858 15.3235 3.06697 16.1283 3.87174C16.933 4.67652 17.5714 5.63192 18.007 6.68341C18.4425 7.7349 18.6667 8.86188 18.6667 10H20C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V1.33333ZM5.23867 15.676L9.23867 9.00933L8.09467 8.324L4.09467 14.9907L5.23867 15.676ZM9.00933 9.24L15.676 5.24L14.9907 4.09467L8.324 8.09467L9.00933 9.24ZM14.7613 4.32533L10.7613 10.992L11.9053 11.6773L15.9053 5.00933L14.7613 4.32533ZM10.9907 10.76L4.324 14.76L5.00933 15.9053L11.676 11.9053L10.9907 10.76Z" fill="#969696"/>
    </svg>
  )
}

export default DiscoverIcon;
