import React from 'react';

interface HomeIconProps {
  className?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ className }) => {
  return (
    <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.591 6.14601L10.52 0.157012C10.204 -0.0529883 9.796 -0.0529883 9.48 0.157012L0.409 6.14701C0.149 6.32001 0 6.60301 0 6.90401V20.087C0 20.589 0.418 21 0.929 21H7.567C8.078 21 8.496 20.59 8.496 20.087V13.012H11.504V20.087C11.504 20.589 11.922 21 12.433 21H19.072C19.582 21 20 20.59 20 20.087V6.90401C20 6.60301 19.841 6.32001 19.591 6.14601ZM18 19L13.5 19.01L13.511 11.913C13.511 11.411 13.093 11 12.583 11H7.44C6.929 11 6.511 11.41 6.511 11.913L6.5 19H2V7.77301L10.011 2.43101L18 7.76401V19Z" fill="#969696"/>
    </svg>
  )
}

export default HomeIcon;
