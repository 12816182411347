import React, { useState, useEffect, useContext, Fragment, useMemo, useRef, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBackUrl,
  setDeleteScrapPopup,
  setIsMainPageLoading,
  setReloadSideNavCollections,
  toggleOrganizeCollection,
  setCollectionAction,
  setCollectionSettingsPopup,
  setWarningPopup,
} from '../../redux/action/utils';
import {
  routerMatchTypes,
  routerLocationTypes,
  routerHistoryTypes,
} from '../../types/router';
import {
  UPSERT_COLLECTION,
  DELETE_COLLECTION,
  COLLECTION_FOR_COLLECTION_PAGE,
} from '../data/collection';
import { entryTypes } from '../../types/feed';
import { removeCollectionFromStore } from '../../redux/action/collectionAction';
import { collectionType } from '../../types/collections';
import { CardViewType } from '../../components/Cards/types/cardView';

import { sendToast } from '../../helpers/notification';

import CollectionFiltersContext from '../../helpers/contexts/collectionFiltersContext';

import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import { ReduxStateType } from '../../redux/store';
import updateEntryCache from '../../helpers/cacheHelpers/updateEntry';
import { getResponseMessages, returnScrapSlug, slugifyString, waitBeforeExecute } from '../../helpers';
import { COLLECTION_SCRAPS_FOR_COLLECTION_PAGE } from '../data/collectionScraps';
import CollectionPage from '../../components/CollectionPage';
import { scrapType } from '../../types/scrapType';
import ScrapCard from '../../components/ScrapCard';
import CollectionSettingsPopup from '../../components/CollectionSettingsPopup';
import DraggableTimeLine from '../../components/DraggableFeed';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import { collectionCardFooterOptionsType } from '../../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import ORGANIZE_COLLECTION_SCRAP from '../data/organizeCollectionScrap';
import NewPopup from '../../components/Global/NewPopup';
import Loader from '../../components/Global/Loader/Loader';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import { ScrapOrderType } from './types';
import { removeScrapCollectionsHandler } from '../NewScrapPopupContainer/helpers/collectionsChangeHandler';
import { setGroupDetailsPopup } from '../../redux/action/popupsAction';
import { REMOVE_COLLECTION_MEMBER } from '../data/collaboration';
import SEOMeta from '../../components/Meta/SEOMeta';
import UPSERT_COMMENT from '../data/upsertComment';
import { CommentType, ReactionType, ScrapCommentsReactionsType, ScrapCommentsType, ScrapReactionCommentCountType } from '../../components/Comments';
import GET_REACTIONS from '../data/getReactions';
import { OneScrapActionType } from '../../redux/store/oneScrap';
import { setCollectionMessenger } from '../../redux/action/messengersAction';
import MATCHED_BOOKMARKS from '../data/matchedBookmarks';
import { updateScrapsLikesAndComments } from '../../redux/action/scrapsLikesAndCommentsActions';
import { updateCollectionsLikesAndComments } from '../../redux/action/collectionsLikesAndCommentsActions';
import returnActiveCollectionSort from '../../helpers/returnCollectionActiveSort';
import { data } from 'cheerio/lib/api/attributes';


const MemoizedScrapCard = React.memo(ScrapCard)


interface Proptypes {
  match: routerMatchTypes;
  location: routerLocationTypes;
  history: routerHistoryTypes;
  view: CardViewType;
  isPublic?: boolean;
}
export const collectionSortConfig = {
  'Last Updated': {
    sort_param: 'updated_at',
    sort_by: 'desc',
  },
  Newest: {
    sort_param: 'created_at',
    sort_by: 'desc',
  },
  Custom: {
    sort_param: '',
    sort_by: '',
    setOnPathNameChange: null,
  },
};
const numberOfRecords = 20;
const layoutViews: Array<'Gallery' | 'Story' | 'Grid'> = [
  'Gallery',
  'Grid',
  'Story',
];

export default function CollectionPageContainer({ view, isPublic }: Proptypes) {
  const { cID: collection_id, username, private_key } = useParams<{
    id: string;
    cID: string;
    username: string;
    private_key: string;
  }>();
  const { search } = useLocation();
  const ref = useRef<HTMLDivElement>();
  const { push } = useHistory();

  const collectionMessenger = useSelector((state: ReduxStateType) => state.messengers.collectionMessenger);
  const user = useSelector((state: ReduxStateType) => state.user);
  const { expanded } = useSelector((state: ReduxStateType) => state.utils);
  const dispatch = useDispatch();
  const [editCollection] = useMutation(UPSERT_COLLECTION());
  const [organizeCollection] = useMutation(ORGANIZE_COLLECTION_SCRAP());
  const [removeCollectionMember] = useMutation(REMOVE_COLLECTION_MEMBER());
  const [, setEditStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const {
    filters: contextFilters,
    setFilters: setContextFilters,
    setCollectionId,
  } = useContext(CollectionFiltersContext);
  const collectionSettings = useSelector(
    (state: ReduxStateType) => state.utils.collectionSettingsPopup
  );
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(numberOfRecords);
  const [organizeIndex, setOrganizeIndex] = useState<number>(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [scrapsComments, setScrapsComments] = useState<ScrapCommentsType[]>([]);
  const scrapsLikesRef = useRef<ReactionType[]>([]);
  const [bookmarkedScrapIds, setBookmarkedScrapIds] = useState<Array<scrapType['id']>>([])
  const organizeIndexExists = typeof organizeIndex === 'number';
  const [deleteStatus, setDeleteStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [usersList, setUsersList] = useState([]);
  const [deleteCollection] = useMutation(DELETE_COLLECTION);
  const isDeleting = deleteStatus.status === 'processing';
  const collectionQueryVariables = {
    collection_id,
    username,
    private_key,
  };
  const {
    data: collectionData,
    loading: collectionLoading,
    refetch: refetchCollection,
    updateQuery: updateCollectionQuery,
  } = useQuery(COLLECTION_FOR_COLLECTION_PAGE, {
    variables: collectionQueryVariables,
    fetchPolicy: 'cache-and-network',
  });

  let collection: collectionType = collectionData?.collection?.data;
  if(!collection?.id) collection = null;
  const selectedSort = returnActiveCollectionSort({ sort_param:collection?.sort_param, sort_by: collection?.sort_by });

  const { data: collectionBookmarkData, updateQuery: updateCollectionBookmarkQuery } = useQuery(MATCHED_BOOKMARKS(), {
    variables: {
      child_collection_ids: [collection_id]
    },
    skip: !collectionData?.collection?.data.id,
    fetchPolicy: 'network-only'
  })
  const isCollectionBookmarked = !!collectionBookmarkData?.matchedBookmarks?.data?.length
  const { data: reactionData } = useQuery(
    GET_REACTIONS(),
    {
      variables: {
        collection_ids: [collection_id],
        reactable_type: 2,
      },
      skip: !collectionData?.collection?.data.id,
      fetchPolicy: 'network-only',
    }
  );
  
  const history = useHistory();
  const [editCollectionStatus, setEditCollectionStatus] = useState(false);
  const collectionScrapsQueryVariables = {
    collection_id,
    username,
    private_key,
    tags: null,
    untagged: false,
    sort_by: null,
    sort_param: null,
    first,
    page,
  };
  switch (selectedSort) {
    case 'Newest':
      collectionScrapsQueryVariables['sort_param'] = 'created_at';
      collectionScrapsQueryVariables['sort_by'] = 'desc'
      break;
    case 'Oldest':
      collectionScrapsQueryVariables['sort_param'] = 'scrap_created_at';
      collectionScrapsQueryVariables['sort_by'] = 'asc'
      break;
  
    default:
      break;
  }
  
  
  if (contextFilters) {
    for (let key in contextFilters) {
      if (
        key === 'untagged' &&
        Array.isArray(contextFilters[key]) &&
        contextFilters[key].length
      )
        collectionScrapsQueryVariables[key] = true;
      else if(key === 'tags' && Array.isArray(contextFilters[key]) &&
      contextFilters[key].length) {
        collectionScrapsQueryVariables[key] = contextFilters[key].map(slug => ({
          slug
        }))
      }  
      else
        collectionScrapsQueryVariables[key] =
          contextFilters[key].toString() || '';
    }
  }
  const { data: scrapsData, loading: scrapsLoading, client, fetchMore, refetch: refetchCollectionScraps } = useQuery(
    COLLECTION_SCRAPS_FOR_COLLECTION_PAGE,
    {
      variables: collectionScrapsQueryVariables,
      skip: !collectionData?.collection?.data.id,
      fetchPolicy: 'cache-and-network',
    }
  );

  const loading = collectionLoading || scrapsLoading;

  const cache = client.cache;

  /**
   * When the data changes update the user list
   * this is used when trust status of a user changes
   */
  useEffect(() => {
    const scrapsUsers: collectionType['members'] = scrapsData?.collectionScraps?.scrap_user_data || [];

    if (scrapsUsers.length) {
      setUsersList(scrapsUsers);
    }
  }, [scrapsData]);

  /**
   * update the is_trusted status of the user
   * find the user in the list and update the trust status
   */
  function toggleTrust(status: boolean, userId: number) {
    setUsersList((prev) => {
      return prev.map((user) => {
        if (user.user_id === userId) {
          return { ...user, is_trusted: status };
        }
        return user;
      });
    });
  }

  let myRole: 'collaborator' | 'owner' = null
  if(collection?.user_name === user.userName) myRole = 'owner';
  else if(collection?.members?.findIndex(member => member.user_id == user.user_id) > -1) myRole = 'collaborator'; 
  const { view_type } = collection || {};

  const scraps: scrapType[] = scrapsData?.collectionScraps?.data;
  const totalScraps: number =
    scrapsData?.collectionScraps?.paginatorInfo?.total;

  const scrapsWithUserData = useMemo(
    () =>
      (scraps || []).map((scrap, i) => {
        const scrapUser = usersList.find(
          (data) => data.user_id == scrap.user_id
        );
        return scrapUser ? { ...scrap, ...scrapUser } : scrap;
      }),
    [scraps, usersList]
  );
  
  const [show, setShow] = useState(false);
  const [event, setEvent] = useState<{
    type: OneScrapActionType['type'];
    scrap: scrapType | entryTypes;
  }>();
  const activeLayout = layoutViews[(collection?.view_type || 1) - 1];
  const oneScrapAction = useSelector(
    (state: ReduxStateType) => state.oneScrapAction
  );

  /**
   * @description Function to remove scrap from cache
   *  This sends the scrap data to Apollo cache to remove it from the cache
   */
  const removeScrapHandler=(scrap, dontFetchMore?: boolean)=>{
    const scrapRef = {
      __ref: `CollectionScrap:${JSON.stringify({ id: scrap.id })}`
    };
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        collectionScraps(prevCache, { DELETE }) {
          return {
            ...prevCache,
            paginatorInfo: {
              ...prevCache.paginatorInfo,
              total: prevCache.paginatorInfo.total - 1,
            },
            data: prevCache.data.filter((item) => {
              // @ts-ignore
              return item.__ref !== scrapRef.__ref;
            }),
            total: prevCache.total - 1,
          };
        },
      },
    });

    const scrapIndex = scraps.findIndex(scrapData => scrapData.id === scrap.id);
    scrapsLikesRef.current.filter((_,i) => i !== scrapIndex)
    setScrapsComments(old =>old.filter((_,i) => i !== scrapIndex))
    setBookmarkedScrapIds(old =>old.filter((_,i) => i !== scrapIndex))
    !dontFetchMore && fetchMore({ variables: collectionScrapsQueryVariables })
  }

  const scrapBookmarkToggledHandler = (scrapIndex: number, action: 'save' | 'unsave') => {
    const scrap = scraps[scrapIndex]
    setBookmarkedScrapIds(old => old.map((id, i) => {
      if(i === scrapIndex) return action === 'save' ? scrap.id : null;
      return id
    }))
  }
  
  const loadBookmarksHandler = async (ids: Array<number | string>, idKey: 'scrap_ids' | 'child_collection_ids') => {
    if(!ids.length) return []
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: {[idKey]: ids}
      })
      const {error,isSuccess} = getResponseMessages(response?.data?.matchedBookmarks)
      if(!isSuccess) throw new Error(error[0])
      const scrapIdsFromResponse: Array<scrapType['id']> = (response?.data?.matchedBookmarks?.data || []).map(item => item?.scrap_id);
      const orderedScrapIds: Array<scrapType['id']> = new Array(ids.length)
      ids.forEach((id, i) =>  {
        orderedScrapIds[i] = scrapIdsFromResponse.find(data => data == id) ?? null;
      })
      return orderedScrapIds;
    } catch (error) {
      
      return []
    }
  }

  const loadReactionsHandler = async (ids: Array<scrapType['id']>, idKey: 'scrap_ids' | 'comment_ids') => {
    if(!ids.length) return [];
    const response = await client.query({query: GET_REACTIONS() , variables: {
      [idKey]: ids,
      reactable_type: idKey === 'scrap_ids' ? 1 : 3      
    }})
    const scrapsReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedScrapsReactions: Array<ReactionType> = new Array(ids.length)
    ids.forEach((id, i) =>  {
      orderedScrapsReactions[i] = scrapsReactions.find(data => data.id == id) ?? null
    })
    return orderedScrapsReactions;
  }

  const loadScrapsCommentsReactionsHandler = async (idsTuples: Array<[scrapType['id'], Array<CommentType['id']>]>) => {
    if(!idsTuples.length) return [];
    const allCommentIds: Array<CommentType['id']> = [];
    const allScrapsId: Array<scrapType['id']> = [];
    
    (idsTuples || []).forEach(([scrapId, commentIds]) => {
      allScrapsId.push(scrapId);
      allCommentIds.push(...(commentIds || []).slice(0,2))
    })
    const reactions = await loadReactionsHandler(allCommentIds, 'comment_ids');
    const scrapsCommentsReactions: ScrapCommentsReactionsType[] = []
    allScrapsId.forEach((scrap_id,i) => {
      const commentsReactions: ReactionType[] = [];
      const scrapCommentsId = idsTuples[i][1] || []
      if(scrapCommentsId) {
        scrapCommentsId.forEach(commentId => {
          const commentReaction = reactions.find(reaction => reaction?.id == commentId)
          if(commentReaction) commentsReactions.push(commentReaction)
        })
      }

      scrapsCommentsReactions.push({
        scrap_id,
        reactions: commentsReactions
      })
    
    })
    return scrapsCommentsReactions;
  }

  useEffect(() => {
    if (scrapsLoading || !scraps?.length) return;

    
    const scrapsCommentsIds = scrapsComments.length
      ? scraps.slice(scrapsComments.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);
    const reactionCommentCounts: ScrapReactionCommentCountType[] = scrapsData?.collectionScraps?.reaction_comment_counts || [];
    const fetchedScrapsComments: ScrapCommentsType[] = scrapsData?.collectionScraps?.collection_scraps_comments || []
    const orderedScrapsComments: ScrapCommentsType[] = new Array(scrapsCommentsIds.length)
    scrapsCommentsIds.forEach((id, i) =>  {
      let scrapComments = fetchedScrapsComments.find(data => data.scrap_id == id) ?? null;
      const countsData = reactionCommentCounts.find(data => data.scrap_id == id) ?? null;
      if(!countsData && !scrapComments) orderedScrapsComments[i] = null;
      orderedScrapsComments[i] = {
        ...(scrapComments || {}),
        ...(countsData || {})
      }
      if(orderedScrapsComments[i]?.comments?.length && !orderedScrapsComments[i].comment_count) {
        orderedScrapsComments[i].comment_count = orderedScrapsComments[i].comments.length;
      }
    });
    
    
    setScrapsComments(
      scrapsComments?.length
        ? [...scrapsComments, ...orderedScrapsComments]
        : orderedScrapsComments
    );


    const reactionsScrapIds = scrapsLikesRef.current.length
      ? scraps.slice(scrapsLikesRef.current.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);
    const bookmarkScrapIdsToFetch = bookmarkedScrapIds.length 
      ? scraps.slice(bookmarkedScrapIds.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);
    
    Promise.all([
      loadReactionsHandler(reactionsScrapIds, 'scrap_ids'),
      loadScrapsCommentsReactionsHandler(scrapsCommentsIds.map((scrapId,i) => {
        const commentIds: Array<CommentType['id']> = []
        if(orderedScrapsComments[i]?.comments) commentIds.push(...orderedScrapsComments[i].comments.map(comment => comment.id))
        return [scrapId, commentIds]
      })),
      loadBookmarksHandler(bookmarkScrapIdsToFetch, 'scrap_ids')
    ]).then((responses) => {
      const [scrapsReactionsResponse, scrapsCommentsReactionsResponse, bookmarkedIds] = responses;
      if(scrapsReactionsResponse) {
        scrapsLikesRef.current.push(...scrapsReactionsResponse)
        dispatch(updateScrapsLikesAndComments(scrapsReactionsResponse.filter(data => !!data).map(item => {
          const scrap = scrapsWithUserData.find(scrapData => item.id == scrapData.id);
    
          return {
            scrapId: item.id,
            data: {
              isLiked: item.is_reacted,
              likesCount: scrap?.reaction_count,
              commentsData: {
                type: 'collection',
                id: collection.id,
                count: orderedScrapsComments.find(data => data.scrap_id ==item.id)?.comment_count,
              }
            }
          }
        })))
      }
      bookmarkedIds.length && setBookmarkedScrapIds(
        bookmarkedScrapIds.length
          ? [...bookmarkedScrapIds, ...bookmarkedIds]
          : bookmarkedIds
      )
      if(scrapsCommentsReactionsResponse.length) {
        const newData = orderedScrapsComments.map((scrapComments, i) => {
          const fetchedReactions = scrapsCommentsReactionsResponse[i]
          if(!fetchedReactions?.reactions?.length) return scrapComments
          return {
            ...scrapComments,
            comments: scrapComments.comments.map((comment, commentIndex) => {
              return {
                ...comment,
                reaction: fetchedReactions.reactions[commentIndex] || null,
              }
            })
          }
        })
        setScrapsComments(scrapsComments?.length ?
          [...scrapsComments, ...newData] : newData)
      }
      
    });
  }, [scrapsLoading, scraps]);
  useEffect(() => {
    if (!collectionMessenger) return;
    if(!collection) {
      dispatch(setCollectionMessenger(null));
      return;
    }
    const { type, commentsCount: comment_count } = collectionMessenger;
    if (type === 'edit' || type === 'member-added') {
      refetchCollection();
    } else if (type === 'delete') {
      //
    } else if(type === 'member-removed') {
      refetchCollection();
      refetchCollectionScraps();
      setPage(1);
    } else if (type === 'likes-and-comments') {
      updateCollectionQuery(prevData => {
        return {
          ...prevData,
          collection: {
            ...prevData.collection,
            data: {
              ...prevData.collection.data,
              comment_count
            }
          }
        }
      })
    }
    dispatch(setCollectionMessenger(null));
  }, [collectionMessenger]);
  useEffect(() => {
    
    if (oneScrapAction.type) {
      
      const { scrap: actionScrap, type: actionType, comments: actionComments } = oneScrapAction;
      let type = actionType;
      if(actionType === 'comments-and-likes') {
        const scrapIndex = scraps.findIndex(scrap => scrap.id === actionComments.scrap_id);
        setScrapsComments(old => {
          return old.map((scrapComment, i) => {
            if(i === scrapIndex) {
              return actionComments
            }
            return scrapComment
          })
        });
      } else setEvent({ type, scrap: actionScrap as scrapType });
      dispatch(setOneScrapAction(null));
    }
  }, [oneScrapAction]);

  useEffect(() => {
    let isScrapRemoved = false;
    if (event) {
      const { scrap, type } = event;
      switch (type) {
        case 'delete':
          removeScrapHandler(scrap)
          
          break;
        case 'edit':
          if (
            scrap.collections &&
            scrap.collections.findIndex((item) => item.id === collection.id) < 0
          ) {
            isScrapRemoved = true;
            break;
          }
          updateEntryCache(cache, scrap, true, 'CollectionScrap');
          break;
        default:
          break;
      }
      if(isScrapRemoved) removeScrapHandler(scrap)
      setEvent(null);
    }
  }, [event]);
  
  useEffect(() => {
    if(!reactionData || !collection) return;
    const reaction: ReactionType = (reactionData?.getReactions?.data || [])[0]
    dispatch(updateCollectionsLikesAndComments({
      collectionId: +collection.id,
      data: {
        isLiked: reaction?.is_reacted,
        likesCount: collection.reaction_count,
        commentsData: {
          count: collection.comment_count
        }
      }
    }));
  },[reactionData])
  const scrapCommentHandler = useCallback(async (scrapId: scrapType['id'], text: string, dontUpdateScrapState?: boolean) => {
    const res = await client.mutate({
      mutation: UPSERT_COMMENT(),
      variables: {
        text,
        scrap_id: scrapId,
        collection_id: collection.id,
        commentable_type: 2,
      }
    })
    const { isSuccess, error } = getResponseMessages(res.data.upsertComment);

    const comment = res.data.upsertComment.data;
    if(!isSuccess) throw new Error(error[0]);
    const scrapCommentsIndex = scraps.findIndex(scrap => scrap.id == scrapId)
    let scrapComments = scrapsComments[scrapCommentsIndex]
    if(scrapComments) {
      const currentCommentsCount = scrapComments.comment_count || scrapComments?.comments?.length || 0;
      scrapComments = {
        ...scrapComments,
        comment_count: currentCommentsCount + 1,
        comments: [...(scrapComments.comments || []), comment],
        commenting_users: (function() {
          const currentCommentingUsers = scrapComments.commenting_users || [];
          const existingUserIndex = (scrapComments?.commenting_users || []).findIndex(commentingUser => commentingUser.user_id === user.user_id)
          if(existingUserIndex > -1) return currentCommentingUsers;
          return [
            ...currentCommentingUsers,
            {
              user_id: user.user_id,
              user_name: user.userName,
              avatar: user.avatar,
              display_name: user.display_name,
            }
          ]
        })()
      }
    } else {
      scrapComments = {
        commenting_users: [{
          user_id: user.user_id,
          user_name: user.userName,
          avatar: user.avatar,
          display_name: user.display_name,
        }],
        comments: [comment],
        scrap_id: scrapId,
        comment_count: 1,
      }
    }
    if(dontUpdateScrapState) return scrapComments;
    setScrapsComments(old => old.map((data,i) => {
      if(i === scrapCommentsIndex) {
        return scrapComments;
      }
      return data;
    }))
    return scrapComments;    
  }, [collection, scraps, scrapsComments])
  async function deleteCollectionHandler() {
    try {
      setDeleteStatus({ status: 'processing' });
      const res = await deleteCollection({
        variables: {
          id: collection?.id,
        },
      });
      const { success, error } = res?.data?.deleteCollection?.messages || {};
      if (success?.length > 0) {
        dispatch(removeCollectionFromStore(collection?.id as string));
        setDeleteStatus({ status: 'success' });
        dispatch(
          setCollectionAction({
            collection: collectionSettings?.collection,
            type: 'delete',
          })
        );
        dispatch(setCollectionSettingsPopup(null));
        setShow(false);
        setShowDeletePopup(false);        
        history.push('/');
      } else {
        let errorMessage = 'Something went wrong';
        if (Array.isArray(error) && error.length > 0) errorMessage = error[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      setDeleteStatus({ status: 'error' });
      sendToast(parseTryCatchError(error), 'error', 2000);
      throw new Error(parseTryCatchError(error));
    }
  }
  //  FUNCTION TO LOAD MORE SCRAPS WHEN SCROLLING
  const loadMoreHandler = useCallback(() => {
    if (!Array.isArray(scraps)) return;
    if (scraps.length % numberOfRecords !== 0) return;
    setPage(scraps.length / numberOfRecords + 1);
  }, [scraps])
  
  function organizeClickHandler() {
    if (organizeIndexExists) {
      setFirst(numberOfRecords);
    } else {
      setFirst(totalScraps);
    }
    setContextFilters(null)
    setPage(1);
    setOrganizeIndex(organizeIndexExists ? null : -1);
    dispatch(toggleOrganizeCollection(!organizeIndexExists));
  }

  async function editHandler(
    data: {
      title?: string;
      desc?: string;
      cover_image?: string;
      view_type?: number;
      sort_param?: string;
      sort_by?: string;
    },
    cb?: () => void
  ) {
    try {
      const newSort = returnActiveCollectionSort({sort_by: data.sort_by, sort_param: data.sort_param});
      setEditStatus({ status: 'processing' });
      const res =  await editCollection({
        variables: {
          id: collection?.id,
          ...data,
        },
      });
      dispatch(
        setCollectionAction({
          type: 'edit',
          collection: res?.data?.upsertCollection,
        })
      );
      if(selectedSort !== newSort) {
        reloadCollectionHandler('scraps');
      }
      
      cb();

      setEditStatus({ status: 'success' });
      dispatch(setCollectionSettingsPopup(null));
      dispatch(setReloadSideNavCollections(true));
    } catch (error) {
      console.warn('error', error);

      setEditStatus({ status: 'error' });
      throw new Error(parseTryCatchError(error));
    }
  }
  async function reloadCollectionHandler(type: 'scraps' | 'collection') {
    if (type === 'collection') {
      // cache.evict({id: 'ROOT_QUERY', fieldName: 'collectionScraps'})
    }
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'collectionScraps' });
    setScrapsComments([]);
    scrapsLikesRef.current = [];
    setBookmarkedScrapIds([]);
    setPage(1);
    setFirst(numberOfRecords);
  }

  async function saveNewOrdersHandler(
    scrapOrders: Array<ScrapOrderType>,
    newPanelsList: scrapType[]
  ) {
    await organizeCollection({
      variables: {
        collection_id: collection.id,
        orders_and_scraps: scrapOrders.map(({ id: scrap_id, order }) => ({
          scrap_id,
          order,
        })),
      },
    });
    reloadCollectionHandler('scraps');
  }
  
  const editClickHandler = (scrap?: scrapType, scrollToBottom?: boolean) => {
    dispatch(setBackUrl(search ?? null, scrollToBottom));
    push(
      `/c/${slugifyString(collection.title, false)}/${
        collection.id
      }/scrap/${returnScrapSlug(scrap)}/edit`
    );
  };

  async function removeScrapFromCollectionHandler(scrap: scrapType) {
    await removeScrapCollectionsHandler(scrap.id, [{ id: collection.id }], client);
    removeScrapHandler(scrap)
  }

  function removeScrapClickHandler(scrap: scrapType) {
    dispatch(
      setWarningPopup({
        warningMessage:"Are you sure you want to remove this Scrap from this Collection?",
        header:"Warning",
        leftLoader:true,
        cancel:{
          label:"Cancel",
        },
        submit:{
          label:"OK",
          cb: removeScrapFromCollectionHandler.bind(null, scrap)
        }
      })
    ) 
  }

  async function leaveCollectionHandler() {
    const res = await removeCollectionMember({
      variables: {
        collection_id: collection.id,
        member_id: user.user_id,
      }
    })
    const { isSuccess, error } = getResponseMessages(
      res.data.removeCollectionMember
    );

    if (isSuccess) {
      // updateGroupDataHandler('member-removed', [member]);
      // dispatch(setReloadSideNavCollections(true));
      dispatch(setReloadSideNavCollections(true));
      history.replace('/');
    } else {
      throw new Error(error[0]);
    }
  }
  async function commentReactionToggledHandler(scrapId: scrapType['id'], scrapIndex: number, commentId: CommentType['id'], isReacted: boolean) {
    setScrapsComments(old => old.map((scrapComments,i) => {
      if(i === scrapIndex) {
        return {
          ...scrapComments,
          comments: (function(){
            return scrapComments.comments.map(comment => {
              if(comment.id == commentId) {
                let newReactionData = comment.reaction ? {...comment.reaction} : null;
                if(newReactionData) newReactionData.is_reacted = isReacted;
                else newReactionData = {
                  id: commentId,
                  is_reacted: isReacted,
                  reactable_type: 3
                }
                return {
                  ...comment,
                  reaction_count: isReacted ? (comment.reaction_count || 0) + 1 : (comment.reaction_count || 0) - 1,
                  reaction: newReactionData

                }
              }
              return comment
            })
          })()
        }
      }
      return scrapComments
    }))
  }  

  async function reactionToggledHandler (scrap_id: number, scrapIndex: number,isReacted: boolean) {
    const scrapReaction = scrapsLikesRef.current[scrapIndex];
    if(scrapReaction) {
      scrapReaction.is_reacted = isReacted;
    } else {
      scrapsLikesRef.current[scrapIndex] = {
        is_reacted: isReacted,
        id: scrap_id,
        reactable_type: 1
      }
    }

  }
  function collectionBookmarkToggledHandler (action: 'save' | 'unsave') {
    updateCollectionBookmarkQuery(prevData => {
      return {
        ...prevData,
        matchedBookmarks: {
          ...prevData.matchedBookmarks,
          data:  action === 'save' ? [{
            child_collection_id: collection.id,
            scrap_id: null
          }] : []
        }
      };
    })
  }

  function footerOptionClickHandler(type: collectionCardFooterOptionsType['list'][number]) {
    switch (type) {
      case 'edit':
        setShow(true)        
        break;
      case 'manageCollaborators':
        dispatch(setGroupDetailsPopup({
          type: 'collectionData',
          data: collection,
        }))
        break;  
      case 'organize':
        organizeClickHandler();
        break;
      case 'leaveCollection':
        dispatch(
          setWarningPopup({
            warningMessage:"Are you sure you want to leave this Collection?",
            header:"Warning",
            leftLoader:true,
            cancel:{
              label:"Cancel",
            },
            submit:{
              label:"Yes",
              cb: leaveCollectionHandler
            },
            processingLabel: 'Leaving'
          })
        ) 
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(contextFilters) {
      setPage(1);
      setScrapsComments([]);
      scrapsLikesRef.current = [];
      setBookmarkedScrapIds([]);
    }
  }, [contextFilters]);

  useEffect(() => {
    const loading = scrapsLoading || collectionLoading;
    dispatch(setIsMainPageLoading(loading && !collection));
  }, [scrapsLoading, collectionLoading, collection]);

  useEffect(() => {
    dispatch(setIsMainPageLoading(collectionLoading && !collection));
  }, [collectionLoading, collection]);

  useEffect(() => {
    if (collection_id) {
      setCollectionId(collection_id);
      setPage(1);
    }
    setScrapsComments([]);
    setBookmarkedScrapIds([])
    scrapsLikesRef.current = [];
  }, [collection_id]);

  const {
    sort_by,
    sort_param,
    tags,
    untagged,
  } = collectionScrapsQueryVariables;
  const msKey = useMemo(() => {
    return (Math.random() * 10000).toString() + 'collection-page'
  }, [
    expanded,
    totalScraps,
    sort_by,
    sort_param,
    tags,
    untagged,
    collection_id
  ]);

  const footerDropdownOptions: collectionCardFooterOptionsType = useMemo(() => {
    const list: collectionCardFooterOptionsType['list'] = myRole === 'owner' ? ['edit', 'manageCollaborators', 'share']: ['share', 'leaveCollection'];
    if(selectedSort === 'Custom' && myRole === 'owner' && scraps?.length > 1) list.push('organize')
    return {
      list: myRole ? list : ['share'],
      onOptionClick: footerOptionClickHandler
    }
  }, [myRole, collection, scraps])
  

  console.log({ usersList} );

  
  const draggableTimeLineRenderer = organizeIndexExists ? (
    <DraggableTimeLine
      ref={ref}
      scrapSize={'Large'}
      feed={scrapsWithUserData}
      view_type={view_type}
      isLoading={(loading && !scraps?.length) || scraps?.length < totalScraps}
      view={'collectionEdit-Organize'} //need to be dynamic based on organize scrap button click
      organizeIndex={organizeIndex}
      setOrganizeIndex={setOrganizeIndex}
      saveNewOrders={saveNewOrdersHandler}
      titleLabel={collection?.title}
      onCancel={organizeClickHandler}
    />
  ) : null;

  return (
    <Fragment>
      <SEOMeta title='Scrappi | Collection' />
      <CollectionSettingsPopup
        show_author={collection?.show_author}
        openOtherFieldsByDefault
        // setCLickedLock={setClickedLock}
        // clickedLock={clickedLock}
        state={collection?.state}
        private_key={private_key}
        visibilityStatus={collection?.status}
        // handleEdit={handleEdit}
        view_type={collection?.view_type || 3}
        collectionSlug={collection?.slug}
        title={collection?.title}
        id={collection?.id}
        currentImage={collection?.cover_image}
        desc={collectionData?.desc || collection?.desc}
        onCollectionEdit={editHandler}
        isEditing={editCollectionStatus}
        controlled={{
          show: show,
          setShow: setShow,
        }}
        collaborators={collection?.members}
        openDeletePopup={setShowDeletePopup.bind(null, true)}
        setEditCollectionStatus={setEditCollectionStatus}
        selectedSort={selectedSort}
      />

      <CollectionPage
        onBookmarkToggled={collectionBookmarkToggledHandler}
        isBookmarked={isCollectionBookmarked}
        ref={ref}
        draggableTimeLineRenderer={draggableTimeLineRenderer}
        masonryKey={msKey}
        collection={collection}
        scraps={scrapsWithUserData}
        collectionLoading={collectionLoading}
        scrapsLoading={scrapsLoading}
        onLoadMore={loadMoreHandler}
        footerDropdownOptions={footerDropdownOptions}
        scrapCount={totalScraps}
        isPublic={isPublic}
        isTrusted={collection?.show_author && usersList.findIndex(data => (data.user_id === collection?.user_id && data.is_trusted)) > -1}
        trustCb={(status => toggleTrust(status, collection?.user_id))}
      >
        {(scrap, i) => {
          
          const canIEditScrap = scrap.user_id === user.user_id;
          let scrapComments: ScrapCommentsType = scrapsComments[i] ? {...scrapsComments[i]} : null;
          
          const commentingUsers = scrapComments?.commenting_users || []
          let comments = scrapComments?.comments || [];
          if(comments.length) {
            comments = comments.map(comment => ({
              ...comment,
              user: commentingUsers.find(user => user.user_id === comment.user_id) ?? comment.user
            }))
          }
          return (
            <MemoizedScrapCard
              onBookmarkToggled={scrapBookmarkToggledHandler.bind(null, i)}
              isBookmarked={!!bookmarkedScrapIds[i]}
              view='collection-page'
              onCommentReactionToggled={commentReactionToggledHandler.bind(null, scrap.id, i)}
              commentsCount={scrapComments?.comment_count || scrapComments?.comments?.length}
              onLikeToggled={reactionToggledHandler.bind(null, scrap.id, i)}
              key={scrap.id}
              comments={comments}
              scrapAllCommentsExtraVariables={{
                collection_id: +collection.id,
                commentable_type: 2,
              }}
              onScrapComment={scrapCommentHandler.bind(null, scrap.id)}
              onRemoveClick={(canIEditScrap || myRole === 'owner') && removeScrapClickHandler.bind(null, scrap)}
              scrap={scrap}
              onEditClick={
                canIEditScrap
                  ? (scrap) => editClickHandler(scrap, false)
                  : null
              }
              onDeleteClick={
                canIEditScrap
                  ? () => {
                    dispatch(
                      setDeleteScrapPopup({
                        scrap: scrap as scrapType,
                        onComplete: (scrap) => {
                          setEvent({ type: 'delete', scrap });
                          dispatch(setDeleteScrapPopup(null));
                        },
                      })
                    );
                  }
                  : null
              }
              trustCb={(status) => toggleTrust(status, scrap.user_id)}
            />
          );
        }}
      </CollectionPage>
      <NewPopup
        size='Small'
        header={{ heading: 'Delete Collection' }}
        controlled={{ show: showDeletePopup, setShow: setShowDeletePopup }}
        footer={{
          onSubmit: () => deleteCollectionHandler(),
          submitLabel: isDeleting ? 'Deleting' : 'Delete Collection',
          disableSubmit: isDeleting,
        }}
      >
        {(close) => (
          <div className="collection-header__delete-popup">
            <Loader loadingStatus={isDeleting} />
            <h3 className="collection-header__delete-popup-heading">
              {collection?.title}
            </h3>
            <p className="collection-header__delete-popup-message">
              Deleting your Collection will not delete the Scraps within it.
            </p>
          </div>
        )}
      </NewPopup>
    </Fragment>
  );
}
