import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {useQuery, gql, useMutation} from '@apollo/client';

import Navigation from '../components/Navigations';
import { loadVisibilityStates } from '../redux/action/visibilityStatesAction';
import { addTopic } from '../redux/action/topicActions';
import '../components/Navigations/SecondaryNavigation.css';
import { loadUser } from '../redux/action/userActions';
import { setDimensions, toggleFilterState } from '../redux/action/utils';
import { GET_USER_SETTINGS } from '../containers/data/getUserSettings';
import {
  setAllViewSettings,
  setLoadingSettings,
} from '../redux/action/viewSettingsActions';
import { parseSettingsFromString } from '../helpers/parseViewSettings';
import { getToken } from '../apollo/getToken';
import { getBrowserTimezone } from '../helpers';
import useWindowResize from '../helpers/customHooks/useWindowResize';
import {AUTO_TRUST} from "../components/Account/Profile/ContactImport/ContactImportQueries";
const GET_BASE_DATA = gql`
  {
    profile {
      data {
        user_id  
        can_send_invite
        userName
        avatar
        userEmail
        phone
        country
        timezone
        bio
        headline
        address
        login_via
        onboard_status
        register_from
        cover_image
        social_media_accounts
        url
        location
        display_name
        services
        main_service
      }
    }
    states {
      data {
        id
        title
        type
      }
    }
  }
`;

const SAVE_USER_CONTACTS_FOR_TRUST = gql`
  mutation UpsertSocialSync($id: ID, $service_id: Int!, $service_response: String, $service_user_id: String, $service_username: String) {
    upsertSocialSync(id: $id, service_id: $service_id, service_response: $service_response, service_user_id: $service_user_id, service_username: $service_username) {
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

const SAVE_USER_SETTINGS = gql`
  mutation upsertSetting($type: String!, $meta: String!) {
    upsertSetting(type: $type, meta: $meta) {
      meta
    }
  }`;


const generateSettingsName = (type: string) => {
  switch (type) {
    case 'collections':
      return 'collectionsViewSettings';
    case 'collectionScraps':
      return 'collectionScrapsViewSettings';
    case 'allScraps':
      return 'allScrapsViewSettings';
    case 'stories':
      return 'storiesViewSettings';
    case "myScrapsPageViewSettings":
      return 'myScrapsPageViewSettings';
    case "scrapLayoutSettings":
      return 'scrapLayoutSettings';
    case "contactSync":
      return 'contactSyncSettings';
    default:
      return 'undefined';
  }
};

const NavigationWrapper = ({
  topics,
  activeTopic,
  user,
  utils,
  hideFilter = false,
  showFullScreen = false
}) => {
  const { width, height } = useWindowResize();
  const [load, setLoad] = useState(true);
  const { pathname } = useLocation();
  const [currentHighlight, setCurrentHighlight] = useState(
    pathname?.split('/')[1] || 'dashboard'
  );

  const [upsertSocialSync] = useMutation(SAVE_USER_CONTACTS_FOR_TRUST);
  const [upsertSettings] = useMutation(SAVE_USER_SETTINGS);
  const [autoTrust] = useMutation(AUTO_TRUST);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { loading, error, data } = useQuery(GET_BASE_DATA, {
    skip: !getToken(),
  });

  const {
    data: settingsData,
    loading: settingsLoading,
    error: settingsError,
  } = useQuery(GET_USER_SETTINGS, {
    variables: {
      type: '',
    },
    skip: !getToken(),
  });
  const dispatch = useDispatch();

  const returnActivePathName = (path) => {
    switch (path) {
      case 'collections':
      case 'c':
        return 'collections';

      case '':
        return 'dashboard';
      default:
        return null;
    }
  };
  //Used to set dimensions of browser in redux
  useEffect(() => {
    dispatch(setDimensions({ width, height }));
  }, [width, height]);

  useEffect(() => {
    if (data && data.states) {
      dispatch(loadVisibilityStates(data.states.data));
      dispatch(loadUser({ ...data.profile.data,timezone:getBrowserTimezone() }));
      dispatch(toggleFilterState(hideFilter));
      setLoad(false);
    }
    if(!loading)
    {
      setLoad(false)
    }
  }, [data]);
  useEffect(() => {
    if (settingsLoading && !settingsData) return;

    const obj = {};
    Array.isArray(settingsData?.userSettings) &&
    settingsData?.userSettings?.forEach((item) => {
      if (item.type && item.meta) {
        obj[generateSettingsName(item.type)] = parseSettingsFromString(
          item.meta
        );
      }
    });

    if (Object.keys(obj).length > 0) {
      dispatch(setAllViewSettings(obj));
    } else dispatch(setLoadingSettings(false));

    // Check if the contactSyncSettings is present in the settingsData
    // if not, then make a request to update the user's login info for social trust
    const contactSyncSettings = obj['contactSyncSettings'];

    if(contactSyncSettings?.status === 'false' || !contactSyncSettings) {
      const listOfLoging = getAllLogins();
      saveUsersLoginContacts(listOfLoging).then(async () => {
        // save user settings to include the contactSyncSettings
        // this makes sure to ignore the logic if the user has already set the contactSyncSettings
        const contactSyncSettings = JSON.stringify({ status: 'true' });
        await upsertSettings({
          variables: {
            type: 'contactSync',
            meta: JSON.stringify(contactSyncSettings),
          },
        });
      });
    }
  }, [settingsData, settingsLoading, data]);

  /**
   * Get all the logins of the user
   */
  function getAllLogins() {
    const profileData = data?.profile?.data;
    const phone = profileData?.phone;
    const services = profileData?.services;

    if(!services && !phone) return null;

    if(!services && phone) return { emails:[], phone: phone };

    try {
      const listOfServices: [{ type: string; email: string }] = JSON.parse(services);
      // only return the unique email as array
      return {
        emails: [...new Set(listOfServices.map(
          item => item.email.toLowerCase()
        ))],
        phone
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Make a request to save the user's login information for social trust
   * these contacts are used to match when the user imports contacts from phone or Google contacts
   */
  async function saveUsersLoginContacts(logins: { emails: Array<string>; phone: string }) {
    // loop through the emails and phone numbers to save them
    for (let i = 0; i < logins.emails.length; i++) {
      const email = logins.emails[i];
      await saveLoginDetails(3, email);
      await saveLoginDetails(4, email);
    }

    if(logins.phone) {
      await saveLoginDetails(3, logins.phone);
      await saveLoginDetails(4, logins.phone);
    }

    // run the auto trust for both the services
    await autoTrust({
      variables: {
        service_id: 3,
      }
    });

    await autoTrust({
      variables: {
        service_id: 4,
      }
    });
  }

  async function saveLoginDetails(service_id: number, service_user_id: string) {
    await upsertSocialSync({
      variables: {
        service_id, // Service ID for device contacts
        service_response: '{}', // Placeholder for service response
        service_username: '_', // Placeholder for service user name
        service_user_id, // Phone number or email to be synchronized
      }
    })
  }

  useEffect(() => {
    if (pathname.startsWith('/scrap/')) {
      setCurrentHighlight('dashboard');
    } else setCurrentHighlight(returnActivePathName(pathname?.split('/')[1]));
  }, [pathname]);

  // if the user is editing the story, hide the navigation
  const activeRouteHasEdit =
    (pathname.indexOf('/edit') >= 0 && pathname.indexOf('/story') >= 0) ||
    (pathname.indexOf('/preview') >= 0 && pathname.indexOf('/story') >= 0) ||
    pathname.indexOf('/published') >= 0;
  if (activeRouteHasEdit) {
    return null;
  }

  if(showFullScreen)
    return null;

  return (
    <Navigation
      topics={topics}
      load={load}
      activeTopic={activeTopic}
      user={user}
      addNewTopic={(topic) => dispatch(addTopic(topic))}
      utils={utils}
      currentHighlight={currentHighlight}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    topics: state.topics,
    activeTopic: state.activeTopic,
    user: state.user,
    utils: state.utils,
  };
};

export default connect(mapStateToProps)(NavigationWrapper);
