import React, { useRef } from 'react';
import { collectionType } from '../../types/collections';

import './styles.css';
import LoadingScrapCard from '../Global/LoadingScrapCard';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import ItemsMasonry from '../ItemsMasonry';
import { ReduxStateType } from '../../redux/store';
import { useSelector } from 'react-redux';
const numberOfCards = 20;
const arr = Array.from(Array(numberOfCards), (_, index) => ({
  id: index + 1,
}));
interface Proptypes {
  collections: collectionType[];
  isLoading?: boolean;
  loadMore?: () => void;
  children: (collection: collectionType, i: number) => React.ReactElement;
  totalCount: number;
  renderControls?: () => React.ReactElement;
  masonryKey?: string;
}

const CollaboratedCollections = ({
  collections,
  isLoading,
  loadMore,
  children,
  totalCount,
  renderControls,
  masonryKey,
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>();
  const user = useSelector((state: ReduxStateType) => state.user);

  let el;
  if (!collections?.length) {
    if (isLoading) {
      return null;
    } else {
      el = (
        <EmptyPageMessage header={{}} register_from={user.register_from}>
          <span>Looks like there are no results.</span>
        </EmptyPageMessage>
      );
    }
  } else {
    el = (
      <ItemsMasonry<collectionType>
        containerRef={ref}
        key={masonryKey}
        items={collections}
        onLoadMore={loadMore}
        loading={isLoading}
        columnGutter={18}
        columnWidth={600}
        columnCount={1}
        overscanBy={2}
      >
        {children}
      </ItemsMasonry>
    );
  }

  return (
    <div className="collaborated-collections collaborated-collections__list" ref={ref}>
      {renderControls && renderControls()}
      {el}
    </div>
  );
};

export default CollaboratedCollections;
