import React, { useEffect, useRef, useState } from 'react';
import { isMobileDevice } from '../../../../helpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CONTACTS, UNLINK_ACCOUNT } from './ContactImportQueries';
import XFollowsCard from './components/XFollowsCard';
import * as config from '../../../../settings.json';
import { useDispatch } from "react-redux";
import { setWarningPopup } from "../../../../redux/action/utils";

/**
 * DeviceContacts Component
 *
 * This component renders a button that allows users to sync their device contacts.
 * It is designed to work within a React Native WebView and only triggers the
 * sync action if the app is running on a mobile device.
 *
 * Features:
 * - Detects if the environment is a mobile app running in a React Native WebView.
 * - Sends a message to the React Native WebView to initiate contact syncing.
 * - Alerts the user if the feature is accessed from an unsupported platform (e.g., web).
 */
const DeviceContacts = () => {
  const [contactsList, setContactsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [getContacts] = useLazyQuery(GET_CONTACTS);
  const isLoadingRef = useRef(true);
  const [totalContacts, setTotalContacts] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const [deleteSocialSync] = useMutation(UNLINK_ACCOUNT);

  useEffect(() => {
    // get the list of contacts with variables
    getContacts({
      variables: {
        first: 100,
        page: currentPage,
        service_id: 3,
      },
    })
      .then((res) => {
        if (!res) return;

        const responseList = res.data?.socialTrusts?.data?.socialTrusts;
        const pagingInfo = res.data?.socialTrusts?.paginatorInfo;
        const total = pagingInfo?.total || 0;

        const contacts = responseList.map((item) => {
          try {
            const contact = JSON.parse(item.social_response);
            return {
              ...item,
              ...contact,
              firstName: contact.givenName || contact.first_name,
              lastName: contact.familyName || contact.last_name,
            };
          } catch (error) {
            return {};
          }
        });

        setTotalContacts(total);
        setHasMorePages(pagingInfo?.hasMorePages || false);
        setContactsList(contacts);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        isLoadingRef.current = false;
      });

    // add a listener to see if the user has scrolled to the bottom of the page
    // add the event listener to the 'profile__wrapper' element as that is the one scrolling
    const profileWrapper = document.querySelector('.profile__wrapper');
    const scrollHandler = (e: Event) => {
      const { scrollHeight, scrollTop, clientHeight } = profileWrapper;

      // if the scroll is more than 300px from the bottom of the page
      if(scrollHeight - scrollTop - 100 <= clientHeight && hasMorePages && !isLoadingRef.current) {
        isLoadingRef.current = true;
        setCurrentPage((prevState) => prevState + 1);
      }
    }
    profileWrapper.addEventListener('scroll', scrollHandler);
    return () => {
      profileWrapper.removeEventListener('scroll', scrollHandler)
    }
  }, []);

  /**
   * UseEffect to check if the user has scrolled to the bottom of the page
   * and get the next page of contacts if they have
   * and append them to the contactsList state
   */
  useEffect(() => {
    if (isServer || currentPage === 1) return;

    if(!hasMorePages) return;

    // get the list of contacts with variables
    getContacts({
      variables: {
        first: 100,
        page: currentPage,
        service_id: 3,
      },
    })
      .then((res) => {
        const responseList = res.data?.socialTrusts?.data?.socialTrusts;
        const pagingInfo = res.data?.socialTrusts?.paginatorInfo;
        const contacts = responseList.map((item) => {
          try {
            const contact = JSON.parse(item.social_response);
            return {
              ...item,
              ...contact,
              firstName: contact.givenName || contact.first_name,
              lastName: contact.familyName || contact.last_name,
            };
          } catch (error) {
            return {};
          }
        });

        setHasMorePages(pagingInfo?.hasMorePages || false);
        setContactsList((prevContacts) => [...prevContacts, ...contacts]);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        isLoadingRef.current = false;
      });
  }, [currentPage]);

  // Check if the code is running on the server or client-side.
  const isServer = typeof window === 'undefined';

  // @ts-ignore - ReactNativeWebView is not a property of window and is custom
  const isMobile = !isServer && window.ReactNativeWebView && isMobileDevice()

  /**
   * Handles the button click event to sync device contacts.
   * Sends a message to the React Native WebView if the app is running on a mobile device.
   * Shows an alert if accessed from a non-mobile platform.
   */
  const syncContactsClickHandler = () => {
    // Ensure the code is running in a browser-like environment in a webview of mobile app, not on the server.
    // @ts-ignore
    if (!isServer && window.ReactNativeWebView && isMobileDevice()) {
      // Send a message to the React Native WebView to initiate contact syncing.
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          requestType: 'sync-device-contacts', // Specifies the action type for the React Native handler.
        })
      );
    } else {
      // Inform the user that the feature is mobile app-specific.
      alert('This can only be used from your mobile app');
    }
  };

  /**
   * Delete the contacts and unlink the service
   */
  async function deleteContacts() {
    setDeleting(true);
    await deleteSocialSync({
      variables: {
        service_id: 3,
      },
    });
    setDeleting(false);
    setContactsList([]);
    reloadPage();
  }

  /**
   * Reload the page
   */
  function reloadPage() {
    // if not server refresh the page
    if(typeof window !== 'undefined') {
      window.location.href = '/profile?device-contacts=true';
    }
  }

  return (
    <div className="device-contacts">
      <div className="x-follows__header">
        <h1 className="x-follows__title">Personal Contacts</h1>
      </div>

      <div>
        <p className="x-follows__instructions">
          Import Contacts from your mobile device. New users will be
          automatically Trusted as they join Scrappi and existing users will be
          Trusted now. Refresh this section every so often to capture new
          Contacts.
        </p>

        {/* Button to trigger the contact sync handler */}
        {!isMobile && (
          <div className="x-follows__instructions">
            <p className="x-follows__instructions--light">
              Use the mobile app to sync your device contacts.
            </p>
            <p>
              <a
                className="new-help-page__image-wrapper"
                href={config?.appStore ? config?.appStore : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="apple-logo" src={'/dashboard/img/appStore.svg'}></img>
              </a>
              {'  '}
              <a
                className="new-help-page__image-wrapper"
                href={config?.playStore ? config?.playStore : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="playstore-Logo"
                  src={'/dashboard/img/playStore.svg'}
                />
              </a>
            </p>
          </div>
        )}

        {isMobile && (
          <button
            className="button button__primary button__block"
            onClick={syncContactsClickHandler}
          >
            Click to Sync your Contacts
          </button>
        )}

        {contactsList?.length > 0 && (
          <p>
            <button
              className="button button__secondary button__block"
              onClick={() => {
                dispatch(
                  setWarningPopup({
                    warningMessage:
                      'Are you sure? Click OK to delete your device contacts.',
                    header: 'Warning',
                    leftLoader:true,
                    cancel: {
                      label: 'Cancel',
                    },
                    submit: {
                      label: 'OK',
                      cb: async () => {
                        await deleteContacts();
                      },
                    },
                  })
                )
              }}
              disabled={deleting}
            >
              {deleting ? 'Deleting Contacts...' : 'Delete Contacts'}
            </button>
          </p>
        )}
      </div>

      <div className={'x-follows__deleting'}>
        {
          contactsList?.length > 0 && (
            <>
              <hr />
              <h3>Imported Contacts {totalContacts && `(${totalContacts})`}</h3>
            </>
          )
        }

        {contactsList?.length > 0 &&
          contactsList?.map((user, index) => {
            const { firstName, lastName, service_user_id, description } = user;
            const profileData = user.profile;

            return (
              <div
                className="x-follows__wrapper"
                key={`${index}-${service_user_id}`}
              >
                <XFollowsCard
                  screenName={service_user_id}
                  name={`${firstName || ''} ${lastName || ''}`}
                  description={description}
                  profileData={profileData}
                  hideUser
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DeviceContacts;
