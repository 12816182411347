import React from 'react';
import { Link } from 'react-router-dom';

const SocialConnectMessage = () => {
  return (
    <div className="social-connect-message">
      <p>
        Add Contacts to share and collaborate with friends and colleagues.<br/>
        Build your Trusted Network by connecting your <Link to="/profile?x-follows=true" >social accounts</Link>.
      </p>
    </div>
  )
}

export default SocialConnectMessage;