import React from 'react';
import { BookmarkIcon, CollectionIcon, MyScrapsIcon } from '../Global/icons';
import './MobileNavigationStyles.css';
import MobileNavigationButton from './Components/MobileNavigationButton';
import Share from '../Global/icons/Share';
import CreateScrapIconToggler from '../CreateScrap/components/CreateScrapIconToggler';
import {useSelector} from "react-redux";
import {ReduxStateType} from "../../redux/store";
import {isMobileDevice} from "../../helpers";
import HomeIcon from "../Global/icons/HomeIcon";
import SearchIcon from "../Global/icons/SearchIcon";
import WebIcon from "../Global/icons/WebIcon";
import DiscoverIcon from "../Global/icons/DiscoverIcon";

/**
 * TODO: things to consider
 * Show the list of navigation items.
 * Hide with CSS and only show in mobile.
 * Highlight the icon when on the page.
 * Ability to add at the bottom will be good. Manage the z-index in this case.
 */
const MobileNavigation = () => {
  // get global user state
  const user = useSelector((state: ReduxStateType) => state.user);

  if(!user.user_id) return null;

  let webView = null;
  if (typeof window !== 'undefined') {
    // @ts-ignore
    webView = !!window?.ReactNativeWebView;
    if (webView) {
      // skip the onboarding on mobile
      try {
        localStorage.setItem('tourEnd', 'true');
      } catch (e) {
        // do nothing
      }
    }
  }

  /**
   * Navigate to the mobile app search page.
   * This function is specifically designed for use within a mobile app using WebView.
   */
  const navigateToMobileAppSearch = () => {
    // Ensure the code is running in a browser-like environment in a webview of mobile app, not on the server.
    // @ts-ignore
    if (typeof window !== "undefined" && window.ReactNativeWebView && isMobileDevice()) {
      // Send a message to the React Native WebView to initiate contact syncing.
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          requestType: 'openSearch', // Specifies the action type for the React
          // Native handler.
        })
      );

      return;
    } else {
      // Inform the user that the feature is mobile app-specific.
      alert('This can only be used from your mobile app');
    }
  };

  /**
   * Navigate to the mobile app discover page.
   * This function is specifically designed for use within a mobile app using WebView.
   */
  const navigateToMobileAppDiscover = () => {
    // Ensure the code is running in a browser-like environment in a webview of mobile app, not on the server.
    // @ts-ignore
    if (typeof window !== "undefined" && window.ReactNativeWebView && isMobileDevice()) {
      // Send a message to the React Native WebView to initiate contact syncing.
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          requestType: 'openDiscover', // Specifies the action type for the React
          // Native handler.
        })
      );

      return;
    } else {
      // Inform the user that the feature is mobile app-specific.
      alert('This can only be used from your mobile app');
    }
  }

  if (webView) {
    return (
      <div
        className="mobile-nav__wrapper"
        style={{
          paddingBottom: webView ? '1.6rem' : '1.6rem',
          height: webView ? '84px' : '84px',
        }}
      >
        <MobileNavigationButton
          icon={<HomeIcon />}
          label="Home"
          link="/"
        />

        <MobileNavigationButton
          icon={<Share type="connected-dots" />}
          label="Shares"
          link="/shares"
        />

        <div className="mobile-nav__button">
          <CreateScrapIconToggler />
        </div>

        <MobileNavigationButton
          icon={<WebIcon />}
          label="Web"
          onClick={navigateToMobileAppSearch}
        />

        <MobileNavigationButton
          icon={<DiscoverIcon />}
          label="Discover"
          onClick={navigateToMobileAppDiscover}
        />
      </div>
    );
  }

  return (
    <div
      className="mobile-nav__wrapper"
      style={{
        paddingBottom: webView ? '1.6rem' : '1.6rem',
        height: webView ? '84px' : '84px',
      }}
    >
      <MobileNavigationButton
        icon={<HomeIcon />}
        label="Home"
        link="/"
      />

      <MobileNavigationButton
        icon={<Share type="connected-dots" />}
        label="Shares"
        link="/shares"
      />

      <div className="mobile-nav__button">
        <CreateScrapIconToggler />
      </div>

      <MobileNavigationButton
        icon={<CollectionIcon />}
        label="Collections"
        customClass="mobile-collection-icon"
        link="/collections"
      />


      <MobileNavigationButton
        icon={<BookmarkIcon isFilled />}
        label="Saved"
        link="/saved"
      />

    </div>
  );
};

export default MobileNavigation;
